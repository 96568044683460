import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { ConfigProvider, Spin, Table } from 'antd';
import { LineChatAdminProps } from "../types/Route";
import zhTWIntl from 'antd/lib/locale/zh_TW';
import { ColumnsType } from "antd/lib/table";


export const LineChatAdmin = (props: LineChatAdminProps) => {
    const columns: ColumnsType<any> = [
        {
            title: '訊息',
            dataIndex: 'event',
            width: '30%',
            render: (text, record: any, index) => {
                if (record.event.type === "postback") {
                    return <div>{JSON.stringify(record.event.postback)}</div>
                } else if (record.event.type === "message") {
                    return <div>{JSON.stringify(record.event.message)}</div>
                } else {
                    return <div>{JSON.stringify(text)}</div>
                }
            }
        },
        {
            title: 'Create At',
            dataIndex: 'createAt',
            width: '30%',
            render: (text, record: any, index) => {
                return <div>{record.createAt.toDate().toLocaleString()}</div>
            }
        }
    ];
    const lineEventsRef = useFirestore()
        .collection('lineEvents')
        .where("event.source.userId", "==", props.chatId)
    const { status, data: lineEvents } = useFirestoreCollectionData(lineEventsRef);
    if (status === "loading") {
        return <Spin />
    }
    lineEvents.sort((a, b) => (a.createAt as any).seconds - (b.createAt as any).seconds).reverse()

    return (
        <ConfigProvider locale={zhTWIntl}>
            <Table dataSource={lineEvents} columns={columns} />;
        </ConfigProvider>
    )
}
