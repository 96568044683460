import { RouteComponentProps, Link, navigate } from "@reach/router"
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Spin, Button } from 'antd';
import '@ant-design/pro-card/dist/card.css'
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import { Program } from '../types/Program'
import { firestoreAutoId } from "../utils/uid";
import { OrganizationContext } from '../context/Organization';
import { useContext } from "react";


export const ProgramsAdmin = (props: RouteComponentProps) => {
  const organization = useContext(OrganizationContext);
  const programsRef = useFirestore().collection('programs').where("organizationId", "==", organization.NO_ID_FIELD)
  const { data: programs, status } = useFirestoreCollectionData<Program>(programsRef);

  const columns: ProColumns<Program>[] = [
    {
      title: '標題',
      dataIndex: 'displayName',
      width: '30%',
    },
    {
      title: '操作',
      valueType: 'option',
      width: 200,
      render: (text, record, _, action) => [
        <Link to={`${record.NO_ID_FIELD}`}>編輯</Link>
      ]
    },
  ];
  if (status !== 'success') {
    return (<Spin />)
  }

  return (
    <div>
      <Button onClick={() => navigate(`./programs/${firestoreAutoId()}`)}>新增模組</Button>
      <ProTable<Program>
        columns={columns}
        // defaultData={(programs as any as Program[])}
        request={(params, sorter, filter) => {
          return Promise.resolve({
            data: (programs as any as Program[]),
            success: true,
          });
        }}
        rowKey="NO_ID_FIELD"
        pagination={{
          showQuickJumper: true,
        }}
        search={false}
        dateFormatter="string"
        headerTitle="模組"
        toolBarRender={false}
      />
    </div>
  )
}