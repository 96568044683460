import { RouteComponentProps } from "@reach/router"
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { ColumnsType, } from "antd/lib/table";
import { FormResult, FormResultDisplay } from "../types/FormResult";
import { Table, Search, TableProvider } from 'table-render';
import { Button, Spin } from "antd";
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';
import { Form } from "../types/Form";
import { LineUser } from "../types/LineUser";

export const FormResultsAdmin = (props: RouteComponentProps) => {
    const organization = useContext(OrganizationContext)
    const formResultsRef = useFirestore().collection('formResults')
    const { data: lineUsers, status: lineUsersStatus } = useFirestoreCollectionData<LineUser>(useFirestore().collection('lineUsers'));
    const { data: forms, status: formStatus } = useFirestoreCollectionData<Form>(useFirestore().collection('forms').where("organizationId", "==", organization.NO_ID_FIELD));
    const { data: formResults, status } = useFirestoreCollectionData<FormResult>(formResultsRef.where("organizationId", "==", organization.NO_ID_FIELD));
    const { data: userProperties, status: userPropertiesStatus } = useFirestoreCollectionData(useFirestore().collection('userProperties').where("organizationId", "==", organization.NO_ID_FIELD));
    const columns: ColumnsType<FormResultDisplay> = [
        {
            title: 'Line名稱',
            dataIndex: 'userDisplay',
            key: 'userDisplay',
            width: 100
        },
        {
            title: '表單名稱',
            dataIndex: 'formDisplay',
            key: 'formDisplay',
            width: 200
        },
        {
            title: '操作',
            width: 200,
            render: (text, record, index) => {
                return <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/${organization.name}/admin/form-results/${record.NO_ID_FIELD}`}>
                    編輯/查看
                </a>
            }
        }
    ];

    const searchApi = (params: any) => {
        let data = formResults.map(formResult => {
            const x = formResult as FormResultDisplay
            x.formDisplay = formNameMap.get(x.formId)?.title || "No Form Name"
            x.userDisplay = `${lineUsersMap.get(x.uid)?.lineDisplayName}/${(userPropertiesMap.get(x.uid)?.name as string)}/${x.uid}`
            return x
        })
        if (params?.formDisplay) {
            data = data.filter(r => r.formDisplay === params.formDisplay)
        }
        if (params?.userDisplay) {
            data = data.filter(r => r.userDisplay === params.userDisplay)
        }
        return {
            rows: data,
            total: data.length,
        };
    };

    if (formStatus !== "success" || status !== "success" || lineUsersStatus !== "success" || userPropertiesStatus !== "success") {
        return <Spin />
    }
    const formNameMap = new Map(forms.map(form => [form.NO_ID_FIELD, form]));
    const lineUsersMap = new Map(lineUsers.map(lineUser => [lineUser.lineUserId, lineUser]))
    const userPropertiesMap = new Map(userProperties.map(userProperty => [userProperty.uid, userProperty]))
    const nameList = Array.from(new Set([""].concat(userProperties.map(userProperty => (userProperty as any).name))))
    const formList = Array.from(new Set([""].concat(forms.map(form => form.title))))
    const schema = {
        type: 'object',
        properties: {
            userDisplay: {
                "title": "使用者",
                "type": "string",
                "enum": nameList,
                "enumNames": nameList,
                "widget": "select",
                "props": {
                    "filterOption": true,
                    "showSearch": true,
                    "optionFilterProp": "children"
                },
                'ui:width': '30%',
            },
            formDisplay: {
                "title": "表單",
                "type": "string",
                "enum": formList,
                "enumNames": formList,
                "widget": "select",
                "props": {
                    "filterOption": true,
                    "showSearch": true,
                    "optionFilterProp": "children"
                },
                'ui:width': '30%',
            },
        },
    };
    return (
        <div>
            <TableProvider>
                <Search schema={schema} api={searchApi} searchBtnRender={(search, clear) => [
                    <Button type="primary" onClick={() => search()}>搜尋</Button>,
                    <Button onClick={() => clear()}>重置</Button>
                ]}
                />
                <Table headerTitle="表單結果" columns={columns} rowKey="NO_ID_FIELD" />
            </TableProvider>
        </div>

    );
}