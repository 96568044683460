import 'firebase/firestore';
import { useFirestoreDocData, useFirestore } from 'reactfire';
import { Row, Skeleton, Spin } from 'antd';
import parse from 'html-react-parser';
import "../css/html-preview.css"
import { Content } from '../types/Content'
import { UserContentProps } from "../types/Route"
import amplitude from 'amplitude-js';
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';

const UserContent = (props: UserContentProps) => {
    const contentRef = useFirestore()
        .collection('contents')
        .doc(props.contentId);
    const organization = useContext(OrganizationContext)
    const { status, data: content } = useFirestoreDocData<Content>(contentRef);

    if (status === 'loading') {
        return (
            <Row justify="center" style={{ minHeight: '85vh' }}>
                <Spin tip="努力讀取文章"/>
            </Row>
        )
    }
    const eventProperties = {
        contentId: content.NO_ID_FIELD,
        contentTitle: content.title,
        organizationId: organization.NO_ID_FIELD,
        organizationName: organization.name,
    }
    amplitude.getInstance().logEvent('USER_CONTENT_START', eventProperties);

    return (
        <Row justify="center" style={{ minHeight: '85vh' }}>
            <div className="html-view">
                {parse(content.htmlData)}
            </div>
        </Row>
    )
}

export default UserContent;