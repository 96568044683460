import 'firebase/firestore';
import { useState } from 'react';
import { useFirestoreDocData, useFirestore } from 'reactfire';
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { ContentsDetailAdminProps } from "../types/Route";
import { Content } from "../types/Content";
import FormRender, { useForm, Error } from 'form-render';
import { Button, Divider, message, Spin } from 'antd'

export const ContentsDetailAdmin = (props: ContentsDetailAdminProps) => {
    const organization = useContext(OrganizationContext)
    const [editorData, setEditorData] = useState("");
    const [formLoading, setFormLoading] = useState(false)

    const form = useForm()

    const schema = {
        "type": "object",
        "properties": {
            "title": {
                "title": "文章標題",
                "type": "string",
                "props": {}
            }
        },
        "displayType": "row"
    }

    const contentUpdateRef = useFirestore().collection('contents').doc(props.contentId)
    const content = useFirestoreDocData<Content>(contentUpdateRef);

    const onFinish = async (data: any, errors: Error[]) => {
        if (errors.length > 0) {
            message.warning("表單有錯誤")
            return
        }
        setFormLoading(true)
        await contentUpdateRef.set({
            title: data.title,
            htmlData: editorData,
            organizationId: organization.NO_ID_FIELD
        }, { merge: true })
        setFormLoading(false)
        message.success("更新成功")
    }

    const onMount = () => {
        form.setValues({
            "title": content.data ? content.data.title : ""
        })
    }
    if (content.status !== 'success') {
        return <Spin />
    }
    return (
        <div>
            <FormRender form={form} schema={schema} onFinish={onFinish} onMount={onMount} />
            <Editor
                apiKey="0e92c6j4m45da9svo58kbrwykqatee1eba3470wdk9781fl7"
                initialValue={content.data ? content.data.htmlData : ""}
                onEditorChange={(a, editor) => {
                    setEditorData(editor.getContent())
                }}
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    language: "zh_TW"
                }}
            />
            <Divider></Divider>
            <Button type="primary" onClick={form.submit} loading={formLoading} >
                    儲存
            </Button>
        </div>

    )
}