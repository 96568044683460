import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { FirebaseAppProvider } from 'reactfire';
import { LiffProvider } from 'react-liff';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
// import "@fontsource/noto-sans-tc"
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConfigProvider } from 'antd';
import zhTWIntl from 'antd/lib/locale/zh_TW';

const liffId = '1655947712-lrVP4jvW' || process.env.REACT_APP_LINE_LIFF_ID;
const stubEnabled = false;

const firebaseConfig = {
  apiKey: "AIzaSyABuiP7kkZqynRKKdoBcvB9qld1HyBgc1Y",
  authDomain: "errk-8c7bf.firebaseapp.com",
  databaseURL: "https://errk-8c7bf-default-rtdb.firebaseio.com",
  projectId: "errk-8c7bf",
  storageBucket: "errk-8c7bf.appspot.com",
  messagingSenderId: "417099588222",
  appId: "1:417099588222:web:92f53f3a739d9c72e2ae2f",
  measurementId: "G-JKB1TGW44T"
};

Sentry.init({
  dsn: "https://bf739fc19cc64ba38dd11d25f7d23bce@o855535.ingest.sentry.io/5819789",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <React.StrictMode>
    <LiffProvider liffId={liffId} stubEnabled={stubEnabled}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <ConfigProvider locale={zhTWIntl}>
          <App />
        </ConfigProvider>
      </FirebaseAppProvider>
    </LiffProvider>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
