import FormRender, { useForm, Error } from 'form-render';
import { Button, Spin, message } from 'antd';
import { userPropertiresTreeData } from '../config/UserProperties'
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { Analysis } from '../types/Analysis'
import { useState } from 'react'
import { AnalysisDetailAdminProps } from '../types/Route';


const schema = {
    "type": "object",
    "displayType": "row",
    "properties": {
        "displayName": {
            "title": "標題",
            "type": "string",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false
        },
        "description": {
            "title": "備註",
            "type": "string",
            "format": "textarea",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "props": {
                "autoSize": false
            }
        },
        "logic": {
            "title": "分析",
            "type": "object",
            "required": false,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "properties": {
                "logicList": {
                    "title": "分析列表",
                    "type": "array",
                    "required": false,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": false,
                    "items": {
                        "type": "object",
                        "properties": {
                            "condition": {
                                "title": "條件",
                                "type": "object",
                                "description": "",
                                "required": false,
                                "disabled": false,
                                "readOnly": false,
                                "hidden": false,
                                "properties": {
                                    "conditionList": {
                                        "title": "若皆符合以下條件",
                                        "type": "array",
                                        "required": false,
                                        "disabled": false,
                                        "readOnly": false,
                                        "hidden": false,
                                        "items": {
                                            "type": "object",
                                            "properties": {
                                                "field": {
                                                    "required": true,
                                                    "disabled": false,
                                                    "readOnly": false,
                                                    "hidden": false,
                                                    "title": '欄位',
                                                    "width": "30%",
                                                    "type": "string",
                                                    "widget": "treeSelect",
                                                    "props": {
                                                        "showSearch": true,
                                                        "treeDefaultExpandAll": false,
                                                        "filterOption": true,
                                                        "optionFilterProp": "children",
                                                        "treeData": userPropertiresTreeData
                                                    },
                                                },
                                                "operation": {
                                                    "title": "運算條件",
                                                    "type": "string",
                                                    "widget": "select",
                                                    "required": true,
                                                    "disabled": false,
                                                    "readOnly": false,
                                                    "hidden": false,
                                                    "width": "30%",
                                                    "enum": [
                                                        ">",
                                                        ">=",
                                                        "<",
                                                        "<=",
                                                        "=="
                                                    ],
                                                    "enumNames": [
                                                        ">",
                                                        ">=",
                                                        "<",
                                                        "<=",
                                                        "=="
                                                    ]
                                                },
                                                "value": {
                                                    "title": "值",
                                                    "type": "string",
                                                    "required": true,
                                                    "disabled": false,
                                                    "readOnly": false,
                                                    "hidden": false,
                                                    "width": "30%",
                                                    "props": {
                                                        "allowClear": false
                                                    }
                                                }
                                            }
                                        },
                                        "props": {
                                            "foldable": false
                                        }
                                    },
                                    "result": {
                                        "title": "結果",
                                        "type": "string",
                                        "format": "textarea",
                                        "required": true,
                                        "disabled": false,
                                        "readOnly": false,
                                        "hidden": false,
                                        "props": {
                                            "allowClear": false
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "props": {
                        "foldable": false
                    }
                }
            }
        }
    }
}

export const AnalysisDetailAdmin = (props: AnalysisDetailAdminProps) => {
    const form = useForm()
    const analysisUpdateRef = useFirestore().collection('analysis').doc(props.analysisId)
    const analysis = useFirestoreDocData<Analysis>(analysisUpdateRef);
    const [formLoading, setFormLoading] = useState(false)

    const onFinish = async (data: any, errors: Error[]) => {
        if (errors.length > 0) {
            message.warning("表單有錯誤")
            return
        }
        setFormLoading(true)
        await analysisUpdateRef.set(data, {merge: true})
        setFormLoading(false)
        message.success("更新成功")
    }

    const onMount = () => {
        form.setValues(analysis.data)
    }


    if (analysis.status !== 'success') {
        return <Spin />
    }

    return (
        <div>
            <FormRender form={form} schema={schema} onFinish={onFinish} onMount={onMount} />
            <Button type="primary" onClick={form.submit} loading={formLoading} >
                送出
            </Button>
        </div>
    )
}