import { Link, navigate, RouteComponentProps } from "@reach/router"
import 'firebase/firestore';
import { useFirestoreDocData, useFirestore, useFirestoreCollectionData } from 'reactfire';
import type { ProColumns } from '@ant-design/pro-table';
import { Form } from '../types/Form'
import { LiffURL, LiffURL2, LIFFURL_CMUH } from "../config/liff";
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message, ConfigProvider, Button, Spin } from 'antd';
import zhTWIntl from 'antd/lib/locale/zh_TW';
import ProTable from '@ant-design/pro-table';
import { firestoreAutoId } from "../utils/uid";

export const FormsAdmin = (props: RouteComponentProps) => {
    const organization = useContext(OrganizationContext)
    const formsRef = useFirestore().collection('forms')
    const { data: forms, status } = useFirestoreCollectionData<Form>(formsRef.where("organizationId", "==", organization.NO_ID_FIELD));

    const columns: ProColumns<Form>[] = [
        {
            title: '標題',
            dataIndex: 'title',
            width: '30%',
        },
        {
            title: '備註',
            key: 'description',
            dataIndex: 'description'
        },
        {
            title: '操作',
            valueType: 'option',
            width: 500,
            render: (text, record, _, action) => [
                <Link to={`./${record.NO_ID_FIELD}`}>
                    <a href="#edit">編輯</a>
                </Link>,
                <a
                    key='preview'
                    href={`/${organization.name}/liff/form/${record.NO_ID_FIELD}?preview=1`}
                    target="_blank"
                    rel="noreferrer"
                >
                    預覽問卷
                </a>,
                <CopyToClipboard text={`${LiffURL2}?formId=${record.NO_ID_FIELD}&r=${Math.random().toString(36).substring(7)}`} onCopy={() => message.info("複製成功")}>
                    <a
                        key='generate'
                        href="#copy"
                    >
                        複製 Line 專用連結
                    </a>
                </CopyToClipboard>,
                <CopyToClipboard text={`${LIFFURL_CMUH}?formId=${record.NO_ID_FIELD}&r=${Math.random().toString(36).substring(7)}`} onCopy={() => message.info("複製成功")}>
                <a
                    key='generate'
                    href="#copy"
                >
                    複製 Line 專用連結 (Cmuh)
                </a>
            </CopyToClipboard>
            ],
        },
    ];
    if (status !== 'success') {
        return <Spin />
    }

    return (
        <div>
            <Button onClick={() => navigate(`./forms/${firestoreAutoId()}`)}>新增問卷</Button>
            <ConfigProvider locale={zhTWIntl}>
                <ProTable<Form>
                    columns={columns}
                    request={(params, sorter, filter) => {
                        return Promise.resolve({
                            data: forms,
                            success: true,
                        });
                    }}
                    rowKey="NO_ID_FIELD"
                    pagination={{
                        showQuickJumper: true,
                    }}
                    search={false}
                    dateFormatter="string"
                    headerTitle="表單"
                    toolBarRender={false}
                />
            </ConfigProvider>
        </div>
    )
}