
import { Router, RouteComponentProps, Link, Redirect } from "@reach/router"
import { useSigninCheck, useAuth } from 'reactfire';
import { Layout, Menu, Spin } from 'antd';
import "./Admin.css"
import { FormsAdmin } from "./admin/FormsAdmin"
import { ProgramsAdmin } from "./admin/ProgramsAdmin"
import { FormResultsAdmin } from "./admin/FormResultsAdmin"
import { Manage } from "./admin/Manage"
import { ContentsAdmin } from "./admin/ContentsAdmin"
import { ContentsDetailAdmin } from "./admin/ContentsDetailAdmin"
import { ProgramDetailAdmin } from "./admin/ProgramDetailAdmin"
import { AnalysisAdmin } from "./admin/AnalysisAdmin"
import { AnalysisDetailAdmin } from "./admin/AnalysisDetailAdmin"
import { AdminUserContext } from "./context/AdminUser";
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { AdminUser } from "./types/AdminUser";
import { OrganizationContext } from './context/Organization';
import { useContext } from 'react';
import amplitude from 'amplitude-js';
import FormResultsDetailAdmin from "./admin/FormResultsDetailAdmin";
import { LineUsersAdmin } from "./admin/LineUsersAdmin";
import { LineUsersDetailAdmin } from "./admin/LineUsersDetailAdmin";
import { FromsDetailAdmin } from "./admin/FormsDetailAdmin";
import { LineChatAdmin } from "./admin/LineChatAdmin";
import { LineChatUserAdmin } from "./admin/LineChatUserAdmin";
import { version } from '../package.json';

const { SubMenu } = Menu;
const { Header, Content, Footer } = Layout;
let Home = (props: RouteComponentProps) => <div>Admin Home</div>

export const Admin = (props: RouteComponentProps) => {
    const auth = useAuth();
    const { status: signinStatus, data: signInCheckResult } = useSigninCheck();
    const organization = useContext(OrganizationContext)

    const adminUsersRef = useFirestore()
        .collection('adminUsers')
        .where("organizationId", "==", organization.NO_ID_FIELD).where("uid", "==", auth.currentUser ? auth.currentUser.uid : "").limit(1)

    const { status: adminUserStatus, data: adminUsers } = useFirestoreCollectionData<AdminUser>(adminUsersRef);

    if (signinStatus !== 'success' || adminUserStatus !== 'success') {
        return (<Spin />)
    }

    if(adminUsers.length===0){
        return <Redirect from="" to="/" noThrow />
    }

    const adminUser = adminUsers[0]
    if (signInCheckResult.signedIn === true && adminUser) {
        amplitude.getInstance().setUserId(signInCheckResult.user.uid);
        return (
            <AdminUserContext.Provider value={adminUser}>
                <Layout className="layout">
                    <Header>
                        <div className="logo">爾科 Errk Admin</div>
                        <Menu theme="dark" mode="horizontal">
                            <Menu.Item key="0"><Link to="./">Home</Link></Menu.Item>
                            <Menu.Item key="1"><Link to="./forms">Forms</Link></Menu.Item>
                            <Menu.Item key="2"><Link to="./form-results">Form Results</Link></Menu.Item>
                            <Menu.Item key="3"><Link to="./programs">Programs</Link></Menu.Item>
                            <Menu.Item key="4"><Link to="./contents">Contents</Link></Menu.Item>
                            <Menu.Item key="5"><Link to="./analysis">Analysis</Link></Menu.Item>
                            <Menu.Item key="6"><Link to="./line-users">Line Users</Link></Menu.Item>
                            <Menu.Item key="7"><Link to="./line-chat-user/">Line Chat Users</Link></Menu.Item>
                            {/* <Menu.Item key="6"><Link to="./manage">Manage</Link></Menu.Item> */}
                            <SubMenu title={`${organization.displayName}: ${adminUser.name}`} style={{ float: "right" }}>
                                <Menu.Item onClick={() => {
                                    auth.signOut();
                                }}>Logout</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Header>
                    <Content>
                        <div className="site-layout-content">
                            <Router>
                                <Home path="/" />
                                <FormsAdmin path="forms" />
                                <FromsDetailAdmin path="forms/:formId" formId="" />
                                <FormResultsAdmin path="form-results" />
                                <FormResultsDetailAdmin path="form-results/:formResultId" formResultId="" />
                                <ProgramsAdmin path="programs" />
                                <ProgramDetailAdmin path="programs/:programId" />
                                <ContentsAdmin path="contents" />
                                <ContentsDetailAdmin path="contents/:contentId" contentId="" />
                                <AnalysisAdmin path="analysis" />
                                <AnalysisDetailAdmin path="analysis/:analysisId" analysisId="" />
                                <LineUsersAdmin path="line-users/" />
                                <LineUsersDetailAdmin path="line-users/:lineUserId" lineUserId="" />
                                <LineChatAdmin path="line-chat/:chatId" chatId="" />
                                <LineChatUserAdmin path="line-chat-user/" />
                                <Manage path="manage" />
                            </Router>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>v{version} | 爾科有限公司 ErrkHealth.com ©2021</Footer>
                </Layout >
            </AdminUserContext.Provider>
        )
    }
    return <Redirect from="" to="/" noThrow />
}
