import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from "reactfire";
import { LineUser } from "../types/LineUser";
import { LineUsersDetailAdminProps } from "../types/Route";
import ProCard from '@ant-design/pro-card';
import { Spin, Card, Descriptions, Divider, Typography, Table } from "antd";
import { UserTimeRelatedData, UserTimeRelatedAggregateData } from "../types/UserTimeRelatedData";
import { Line } from '@ant-design/charts';

const { Title } = Typography;
const { Meta } = Card;

const columns = [
    {
        title: 'Record Time',
        dataIndex: 'recordTimeDisplay',
        key: 'recordTimeDisplay',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
];

export const LineUsersDetailAdmin = (props: LineUsersDetailAdminProps) => {
    const lineUsersRef = useFirestore().collection('lineUsers')
    const userTimeRelatedDataRef = useFirestore().collection('userTimeRelatedData')
    const { data: lineUser, status: lineUserStatus } = useFirestoreDocData<LineUser>(lineUsersRef.doc(props.lineUserId));
    const { data: userProperties, status: userPropertiesStatus } = useFirestoreDocData<{ [key: string]: any }>(useFirestore().collection('userProperties').doc(props.lineUserId));
    const [uid, organizationId] = props.lineUserId.split("+")
    const { data: userTimeRelatedData, status: userTimeRelatedDataStatus } = useFirestoreCollectionData<UserTimeRelatedData>(userTimeRelatedDataRef.where("uid", "==", uid).where("organizationId", "==", organizationId))


    if (lineUserStatus !== "success" || userPropertiesStatus !== "success" || userTimeRelatedDataStatus !== 'success') {
        return <Spin />
    }

    let userTimeRelatedFormatData: { [key: string]: UserTimeRelatedAggregateData } = {}
    userTimeRelatedData.forEach(x => {
        if (userTimeRelatedFormatData[x.typeKey] === undefined) {
            userTimeRelatedFormatData[x.typeKey] = {
                typeKey: x.typeKey,
                valueType: x.valueType,
                dataList: []
            }
        }
        userTimeRelatedFormatData[x.typeKey].dataList.push(
            { recordTime: x.recordTime.toDate(), recordTimeDisplay: x.recordTime.toDate().toLocaleString(), value: x.value }
        )
        userTimeRelatedFormatData[x.typeKey].dataList.sort((a, b) => a.recordTime.getTime() - b.recordTime.getTime())
    })

    return <div>
        <ProCard split="vertical">
            <ProCard title="個人資訊" colSpan="30%">
                <Card
                    style={{ width: 300 }}
                    cover={
                        <img
                            alt="example"
                            src={lineUser.linePictureUrl}
                        />
                    }
                >
                    <Meta
                        title={lineUser.lineDisplayName}
                        description={lineUser.createAt.toDate().toLocaleDateString()}
                    />
                </Card>

            </ProCard>
            <ProCard title="數值" headerBordered>
                <div>
                    <Descriptions title="User Properties">
                        {Object.entries(userProperties).map((x => {
                            return <Descriptions.Item label={x[0]}>{x[1]}</Descriptions.Item>
                        }))}
                    </Descriptions>
                    <Divider></Divider>
                    {Object.entries(userTimeRelatedFormatData).map((x) => {
                        const [key, value] = x
                        if (value.valueType === "number") {
                            return <div>
                                <Title level={4} key={key}>{key}</Title>
                                <Line data={value.dataList}
                                    height={200}
                                    width={200}
                                    xField={'recordTimeDisplay'}
                                    yField={'value'}
                                    point={
                                        {
                                            size: 5,
                                            shape: 'diamond',
                                        }
                                    }
                                />
                                <Divider></Divider>
                            </div>
                        } else {
                            return <div>
                                <Title level={4} key={key}>{key}</Title>
                                <Table dataSource={value.dataList} columns={columns} />
                                <Divider></Divider>
                            </div>
                        }
                    })}
                </div>
            </ProCard>
        </ProCard>
    </div>
}