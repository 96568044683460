import { BaseRouteProps } from "../types/Route"
import { Button, Typography, message, Form, Input } from 'antd';
import { useAuth } from 'reactfire'
import { useState, useContext } from 'react'
import { useNavigate } from "@reach/router"
import { OrganizationContext } from '../context/Organization';

const { Title } = Typography;
const Login = (props: BaseRouteProps) => {
    const [submitLoading, setSubmitLoading] = useState(false)
    const auth = useAuth();
    const navigate = useNavigate();
    const organization = useContext(OrganizationContext)

    const onFinish = async (values: any) => {
        setSubmitLoading(true)
        try {
            await auth.signInWithEmailAndPassword(values.email, values.password);
            setSubmitLoading(false)
            message.success("登入成功")
            navigate('admin/', { replace: true })
        } catch {
            setSubmitLoading(false)
            message.error("登入失敗")
        }
    };

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <div style={{ padding: '15px', backgroundColor: 'white', minHeight: '100vh', maxWidth: '500px', margin: '0 auto' }}>
            <Title level={4}>{organization.displayName} 管理員登入</Title>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: '請輸入 Email' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="密碼"
                    name="password"
                    rules={[{ required: true, message: '請輸入密碼' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={submitLoading}>
                        登入
                </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Login;