import { Link, navigate, RouteComponentProps } from "@reach/router"
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { message, ConfigProvider, Button, Spin, List, Typography, Table } from 'antd';
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import zhTWIntl from 'antd/lib/locale/zh_TW';
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';
import { ColumnsType } from "antd/lib/table";

export const LineChatUserAdmin = (props: RouteComponentProps) => {
    const organization = useContext(OrganizationContext)
    if(organization.name==='cmuh'){
        
    }
    const chatId = 'U70e6418a836363167021d3d82bd35f3b';

    const lineEventsRef = useFirestore()
        .collection('lineChatUsers')
        .where("destination", "==", chatId)

    const { status, data: lineEvents } = useFirestoreCollectionData(lineEventsRef);

    if (status === "loading") {
        return <Spin />
    }


    const columns: ColumnsType<any> = [
        {
            title: 'Line Name',
            dataIndex: 'lineDisplayName',
            width: '20%',
        },
        {
            title: 'Line User Id',
            dataIndex: 'lineUserId',
            width: '20%',
        },
        {
            title: 'Last Message Time',
            dataIndex: 'lastEventAt',
            width: '20%',
            render: (t: any) => {
                if(t){
                    return (new Date(t.seconds*1000)).toLocaleString()
                }
                return 'no info'
            }
        },
        {
            title: 'Content',
            dataIndex: 'lastEvent',
            width: '50%',
            render: (d: any) => {
                if(d === undefined)
                    return 'no info'
                if(d.message)
                    return JSON.stringify(d.message)
                if(d.postback)
                    return JSON.stringify(d.postback)
                return JSON.stringify(d)
            }
        },
        {
            title: '操作',
            width: 200,
            render: (text, record: any, index) => {
                return <Link to={`../line-chat/${record.lineUserId}`}>訊息</Link>
            }
        },
    ];
    lineEvents.sort((a, b) => (a.createAt as any).seconds - (b.createAt as any).seconds).reverse()
    return (
        <ConfigProvider locale={zhTWIntl}>
            <Table dataSource={lineEvents} columns={columns} />
        </ConfigProvider>
    )
}
