import { Link, RouteComponentProps } from "@reach/router"
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Spin, ConfigProvider, Avatar, Space, Button } from 'antd';
import '@ant-design/pro-card/dist/card.css'
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import zhTWIntl from 'antd/lib/locale/zh_TW';
import { LineUser } from "../types/LineUser";
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';

export const LineUsersAdmin = (props: RouteComponentProps) => {
    const lineUsersRef = useFirestore().collection('lineUsers')
    const organization = useContext(OrganizationContext)

    const { data: lineUsers, status } = useFirestoreCollectionData<LineUser>(lineUsersRef.where("organizationId", "==", organization.NO_ID_FIELD));

    const columns: ProColumns<LineUser>[] = [
        {
            title: 'Line 名稱',
            dataIndex: 'lineDisplayName',
            width: '30%',
            render: (dom, lineUser) => {
                return (<Space>
                    <Avatar src={lineUser.linePictureUrl} />
                    {lineUser.lineDisplayName}
                </Space>)
            }
        },
        {
            title: 'Line 系統 ID',
            dataIndex: 'lineUserId',
            width: '30%',
        },
        {
            title: '加入時間',
            dataIndex: 'createAt',
            width: '30%',
            render: (dom, lineUser) => {
                return lineUser.createAt.toDate().toLocaleString()
            }
        },
        {
            title: '操作',
            width: 200,
            render: (text, record, index) => {
                return <Link to={`./${record.NO_ID_FIELD}`}>
                    <Button>查看</Button>
                </Link>
            }
        }

    ];
    if (status !== 'success') {
        return (<Spin />)
    }
    console.log(lineUsers)
    return (
        <div>
            <ConfigProvider locale={zhTWIntl}>
                <ProTable<LineUser>
                    columns={columns}
                    request={(params, sorter, filter) => {
                        return Promise.resolve({
                            data: lineUsers,
                            success: true,
                        });
                    }}
                    rowKey="NO_ID_FIELD"
                    pagination={{
                        showQuickJumper: true,
                    }}
                    search={false}
                    dateFormatter="string"
                    headerTitle="文章"
                    toolBarRender={false}
                />
            </ConfigProvider>
        </div>
    )
}