import { userPropertiresTreeData } from '../config/UserProperties'

export const commonSettings = {
  $id: {
    title: 'ID',
    description: '數據儲存的名稱/英文/必填',
    type: 'string',
    widget: 'idInput',
  },
  calculated: {
    title: '計算',
    type: 'string',
    format: "textarea"
  },
  roundNumber: {
    title: '四捨五入到小數點位數',
    type: 'number'
  },
  visibleOp: {
    title: "顯示條件 (比較)",
    type: "string",
    enum: [
      ">",
      "=",
      "<",
      "~",
      ""
    ],
    enumNames: [
      ">",
      "=",
      "<",
      "區間",
      ""
    ],
    widget: "radio"
  },
  visibleValue: {
    title: '顯示條件 (值/區間下界)',
    type: "number",
  },
  visibleValue2: {
    title: '顯示條件 (區間上界)',
    type: "number",
  },
  userPropertyKey: {
    title: '存到使用者',
    type: "string",
    widget: "treeSelect",
    props: {
      "showSearch": true,
      "treeDefaultExpandAll": false,
      "filterOption": true,
      "optionFilterProp": "children",
      "treeData": userPropertiresTreeData
    },
  },
  userTimeRelatedDataKey: {
    title: 'User Time Related Data',
    type: "string",
    widget: "treeSelect",
    description: '預設使用當前時間, 或是使用 recordDate 欄位',
    props: {
      "showSearch": true,
      "treeDefaultExpandAll": false,
      "filterOption": true,
      "optionFilterProp": "children",
      "treeData": userPropertiresTreeData
    },
  },
  title: {
    title: '標題',
    type: 'string',
  },
  description: {
    title: '說明',
    type: 'string',
  },
  default: {
    title: '預設值',
    type: 'string',
  },
  required: {
    title: '必填',
    type: 'boolean',
  },
  placeholder: {
    title: '佔位符',
    type: 'string',
  },
  bind: {
    title: 'Bind',
    type: 'string'
  },
  min: {
    title: '最小值',
    type: 'number'
  },
  max: {
    title: '最大值',
    type: 'number'
  },
  disabled: {
    title: '禁止',
    type: 'boolean',
  },
  readOnly: {
    title: '只讀',
    type: 'boolean',
  },
  hidden: {
    title: '隱藏',
    type: 'boolean',
  },
  width: {
    title: '元素寬度',
    type: 'string',
    widget: 'percentSlider',
  },
  labelWidth: {
    title: '標簽寬度',
    description: '默認值120',
    type: 'number',
    widget: 'slider',
    max: 400,
    props: {
      hideNumber: true,
    },
  },
};

export const globalSettings = {
  type: 'object',
  properties: {
      column: {
          title: '整體佈局',
          type: 'number',
          enum: [1, 2, 3],
          enumNames: ['一行一列', '一行二列', '一行三列'],
          props: {
              placeholder: '默認一行一列',
          },
      },
      labelWidth: {
          title: '標簽寬度',
          type: 'number',
          widget: 'slider',
          max: 300,
          props: {
              hideNumber: true,
          },
      },
      displayType: {
          title: '標簽展示模式',
          type: 'string',
          default: 'column',
          enum: ['row', 'column'],
          enumNames: ['同行', '單獨一行'],
          widget: 'radio',
      },
  },
};

export const baseCommonSettings = {
  title: {
    title: '標題',
    type: 'string',
  },
  type: {
    title: '類型',
    type: 'string',
    hidden: '{{true}}',
  },
  widget: {
    title: '組件',
    type: 'string',
    hidden: '{{true}}',
  },
  format: {
    title: '格式',
    type: 'string',
    hidden: '{{true}}',
  },
};

export const defaultCommonSettings = {
  $id: {
    title: 'ID',
    description: '欄位名稱/英文',
    type: 'string',
    widget: 'idInput',
    required: true,
  },
  title: {
    title: '標題',
    type: 'string',
  },
  description: {
    title: '說明',
    type: 'string',
  },
  default: {
    title: '默認值',
    type: 'string',
  },
  required: {
    title: '必填',
    type: 'boolean',
  },
  placeholder: {
    title: '占位符',
    type: 'string',
  },
  bind: {
    title: 'Bind',
    type: 'string',
  },
  min: {
    title: '最小值',
    type: 'number',
  },
  max: {
    title: '最大值',
    type: 'number',
  },
  disabled: {
    title: '禁用',
    type: 'boolean',
  },
  readOnly: {
    title: '只讀',
    type: 'boolean',
  },
  hidden: {
    title: '隱藏',
    type: 'boolean',
  },
  width: {
    title: '元素寬度',
    type: 'string',
    widget: 'percentSlider',
  },
  labelWidth: {
    title: '標簽寬度',
    description: '默認值120',
    type: 'number',
    widget: 'slider',
    max: 400,
    props: {
      hideNumber: true,
    },
  },
};

// widget 用於指定 schema 右側配置對應的 setting
export const elements = [
  {
    text: '輸入框',
    name: 'input',
    schema: {
      title: '輸入框',
      type: 'string',
    },
    setting: {
      props: {
        title: '選項',
        type: 'object',
        labelWidth: 80,
        properties: {
          allowClear: {
            title: '是否帶清除按鈕',
            description: '填寫內容後才會出現x哦',
            type: 'boolean',
          },
          addonBefore: {
            title: '前tab',
            type: 'string',
          },
          addonAfter: {
            title: '後tab',
            type: 'string',
          },
          prefix: {
            title: '前綴',
            type: 'string',
          },
          suffix: {
            title: '後綴',
            type: 'string',
          },
        },
      },
      minLength: {
        title: '最短字數',
        type: 'number',
      },
      maxLength: {
        title: '最長字數',
        type: 'number',
      },
      pattern: {
        title: '校驗正則表達式',
        type: 'string',
        props: {
          placeholder: '填寫正則表達式',
        },
      },
    },
  },
  {
    text: '大輸入框',
    name: 'textarea',
    schema: {
      title: '編輯框',
      type: 'string',
      format: 'textarea',
    },
    setting: {
      props: {
        title: '選項',
        type: 'object',
        labelWidth: 80,
        properties: {
          autoSize: {
            title: '高度自動',
            type: 'boolean',
          },
          row: {
            title: '指定高度',
            type: 'number',
          },
        },
      },
      minLength: {
        title: '最短字數',
        type: 'number',
      },
      maxLength: {
        title: '最長字數',
        type: 'number',
      },
      pattern: {
        title: '校驗正則表達式',
        type: 'string',
        props: {
          placeholder: '填寫正則表達式',
        },
      },
    },
  },
  {
    text: '日期選擇',
    name: 'date',
    schema: {
      title: '日期選擇',
      type: 'string',
      format: 'date',
    },
    setting: {
      format: {
        title: '格式',
        type: 'string',
        enum: ['dateTime', 'date', 'time'],
        enumNames: ['日期時間', '日期', '時間'],
      },
    },
  },
  {
    text: '數字輸入框',
    name: 'number',
    schema: {
      title: '數字輸入框',
      type: 'number',
    },
    setting: {},
  },
  {
    text: '是否選擇',
    name: 'checkbox',
    schema: {
      title: '是否選擇',
      type: 'boolean',
      widget: 'checkbox',
    },
    setting: {
      default: {
        title: '是否默認勾選',
        type: 'boolean',
      },
    },
  },
  {
    text: '是否switch',
    name: 'switch',
    schema: {
      title: '是否選擇',
      type: 'boolean',
      widget: 'switch',
    },
    setting: {
      default: {
        title: '是否默認開啟',
        type: 'boolean',
      },
    },
  },
  {
    text: '下拉單選',
    name: 'select',
    schema: {
      title: '單選',
      type: 'string',
      enum: ['a', 'b', 'c'],
      enumNames: ['早', '中', '晚'],
      widget: 'select',
    },
    setting: {
      enum: {
        title: '選項欄位',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
      enumNames: {
        title: '選項名稱',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
    },
  },
  {
    text: '點擊單選',
    name: 'radio',
    schema: {
      title: '單選',
      type: 'string',
      enum: ['a', 'b', 'c'],
      enumNames: ['早', '中', '晚'],
      widget: 'radio',
    },
    setting: {
      enum: {
        title: '選項欄位',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
      enumNames: {
        title: '選項名稱',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
    },
  },
  {
    text: '下拉多選',
    name: 'multiSelect',
    schema: {
      title: '多選',
      description: '下拉多選',
      type: 'array',
      items: {
        type: 'string',
      },
      enum: ['A', 'B', 'C', 'D'],
      enumNames: ['杭州', '武漢', '湖州', '貴陽'],
      widget: 'multiSelect',
    },
    setting: {
      enum: {
        title: '選項欄位',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
      enumNames: {
        title: '選項名稱',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
    },
  },
  {
    text: '點擊多選',
    name: 'checkboxes',
    schema: {
      title: '多選',
      type: 'array',
      widget: 'checkboxes',
      items: {
        type: 'string',
      },
      enum: ['A', 'B', 'C', 'D'],
      enumNames: ['杭州', '武漢', '湖州', '貴陽'],
    },
    setting: {
      enum: {
        title: '選項欄位',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
      enumNames: {
        title: '選項名稱',
        type: 'array',
        enum: [],
        widget: 'select',
        props: {
          mode: 'tags',
        },
      },
    },
  },
  {
    text: 'HTML',
    name: 'html',
    schema: {
      title: 'HTML',
      type: 'string',
      widget: 'html',
    },
    setting: {
      default: {
        title: '展示內容',
        type: 'string',
      },
    },
  },
];

export const advancedElements = [
  {
    text: '日期範圍',
    name: 'dateRange',
    schema: {
      title: '日期範圍',
      type: 'range',
      format: 'dateTime',
      props: {
        placeholder: ['開始時間', '結束時間'],
      },
    },
    setting: {
      format: {
        title: '類型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['日期時間', '日期'],
      },
    },
  },
  {
    text: '數字（slider）',
    name: 'slider',
    schema: {
      title: '帶滑動條',
      type: 'number',
      widget: 'slider',
    },
    setting: {},
  },
  {
    text: '圖片展示',
    name: 'image',
    schema: {
      title: '圖片展示',
      type: 'string',
      format: 'image',
    },
    setting: {},
  },
  {
    text: '顏色選擇',
    name: 'color',
    schema: {
      title: '顏色選擇',
      type: 'string',
      format: 'color',
    },
    setting: {},
  },
];

export const layouts = [
  {
    text: '對象',
    name: 'object',
    schema: {
      title: '對象',
      type: 'object',
      properties: {},
    },
    setting: {},
  },
  {
    text: '常規列表',
    name: 'list',
    schema: {
      title: '數組',
      type: 'array',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小長度',
        type: 'number',
      },
      max: {
        title: '最大長度',
        type: 'number',
      },
      props: {
        title: '選項',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折疊',
            type: 'boolean',
          },
          hideDelete: {
            title: '隱藏刪除按鈕',
            type: 'string',
          },
          hideAdd: {
            title: '隱藏新增/復制按鈕',
            type: 'string',
          },
        },
      },
    },
  },
  {
    text: '簡單列表',
    name: 'list1',
    schema: {
      title: '數組',
      type: 'array',
      widget: 'list1',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小長度',
        type: 'number',
      },
      max: {
        title: '最大長度',
        type: 'number',
      },
      props: {
        title: '選項',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折疊',
            type: 'boolean',
          },
          hideTitle: {
            title: '隱藏標題',
            type: 'boolean',
          },
          hideDelete: {
            title: '隱藏刪除按鈕',
            type: 'string',
          },
          hideAdd: {
            title: '隱藏新增/復制按鈕',
            type: 'string',
          },
        },
      },
    },
  },
  {
    text: '表格列表',
    name: 'list2',
    schema: {
      title: '數組',
      type: 'array',
      widget: 'list2',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小長度',
        type: 'number',
      },
      max: {
        title: '最大長度',
        type: 'number',
      },
      props: {
        title: '選項',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折疊',
            type: 'boolean',
          },
          hideDelete: {
            title: '隱藏刪除按鈕',
            type: 'string',
          },
          hideAdd: {
            title: '隱藏新增/復制按鈕',
            type: 'string',
          },
        },
      },
    },
  },
  {
    text: '復雜表格列表',
    name: 'list3',
    schema: {
      title: '數組',
      type: 'array',
      widget: 'list3',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小長度',
        type: 'number',
      },
      max: {
        title: '最大長度',
        type: 'number',
      },
      props: {
        title: '選項',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折疊',
            type: 'boolean',
          },
          hideDelete: {
            title: '隱藏刪除按鈕',
            type: 'string',
          },
          hideAdd: {
            title: '隱藏新增/復制按鈕',
            type: 'string',
          },
        },
      },
    },
  },
];

const saves = [
  {
    text: '複雜結構樣例',
    name: 'something',
    schema: {
      title: '對象',
      description: '這是一個對象類型',
      type: 'object',
      properties: {
        inputName: {
          title: '簡單輸入框',
          type: 'string',
        },
        selectName: {
          title: '單選',
          type: 'string',
          enum: ['a', 'b', 'c'],
          enumNames: ['早', '中', '晚'],
        },
        dateName: {
          title: '時間選擇',
          type: 'string',
          format: 'date',
        },
        listName: {
          title: '對象數組',
          description: '對象數組嵌套功能',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              rangeName: {
                title: '日期/時間範圍',
                type: 'range',
                format: 'date',
                props: {
                  placeholder: ['開始日期', '結束日期'],
                },
              },
            },
          },
        },
      },
    },
  },
];

export const settings = [
  {
    title: '基礎組件',
    widgets: elements,
    show: true,
    useCommon: true, // TODO: 是否將common
  },
  {
    title: '高級組件',
    widgets: advancedElements,
  },
  {
    title: '佈局組件',
    widgets: layouts,
  },
  {
    title: '模板',
    widgets: saves,
  },
];
