
import { Router, RouteComponentProps } from "@reach/router"
import { Admin } from './Admin'
import { Public } from './Public'
import 'firebase/auth';
import 'firebase/functions';
import LiffLogin from './public/LiffLogin';
import LiffLoginCallback from './public/LiffLoginCallback';
import Login from './admin/Login';
import { BaseRouteProps } from "./types/Route";
import { OrganizationContext } from './context/Organization';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Organization } from '../src/types/Organization'
import { useAuth } from 'reactfire';
import { Button, Row, Spin } from 'antd'
import { Prelogin } from "./admin/PreLogin";
import amplitude from 'amplitude-js';
import Layout, { Content, Footer } from "antd/lib/layout/layout";
import { version } from '../package.json';

let SignOut = (props: RouteComponentProps) => {
    const auth = useAuth();
    return <div><Button onClick={() => {
        auth.signOut().then(() => {
            console.log("signout success")
        })
    }}>SignOut</Button></div>
}

const OrganizationApp = (props: BaseRouteProps) => {
    const organizationRef = useFirestore()
        .collection('organizations')
        .where("name", "==", props.organizationName).limit(1)

    const { status, data: organizations } = useFirestoreCollectionData<Organization>(organizationRef);
    if (status !== "success") {
        return (
            <Layout className="layout">
                <Content style={{ minHeight: '85vh' }}>
                    <Row justify="center">
                        <Spin tip="讀取中" />
                    </Row>
                </Content>
                <Footer style={{ textAlign: 'center' }}>v{version} | 爾科有限公司 ErrkHealth.com ©2021</Footer>
            </Layout>
        )
    }

    if (organizations.length === 0) {
        return <div>organization not found</div>
    }

    return (
        <OrganizationContext.Provider value={organizations[0]}>
            <Router>
                <Login path="admin-login/" />
                <Admin path="admin/*" />
                <Public path="liff/*" />
            </Router>
        </OrganizationContext.Provider>
    )
}

export const App = (props: RouteComponentProps) => {
    amplitude.getInstance().init("5e8ebf7122d91b60118d97ae28951810");
    amplitude.getInstance().logEvent('LOAD_APP', {version});
    return (
        <Router>
            <Prelogin path="/" />
            <SignOut path="signout/" />
            <LiffLogin path="liff-login/" />
            <LiffLoginCallback path="liff-login/callback/" />
            <OrganizationApp path=":organizationName/*" />
        </Router>
    )
}

