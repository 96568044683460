import { Redirect, RouteComponentProps, navigate } from "@reach/router"
import { useLiff } from 'react-liff';
import 'firebase/functions';
import { useSigninCheck, useAuth, useUser, useFunctions } from 'reactfire';
import { useQuery } from '../hooks'
import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import { useSessionStorageState } from 'ahooks';

const LiffLogin = (props: RouteComponentProps) => {
    const { liff, ready, isLoggedIn } = useLiff();
    const { status: signinStatus, data: signInCheckResult } = useSigninCheck();
    const formId = useQuery('formId') || "";
    const contentId = useQuery('contentId') || "";
    const liffState = useQuery('liff.state') || "";
    const organization = useQuery('organization');
    const [lineAccessToken, setLineAccessToken] = useState("")
    const functions = useFunctions();
    const [nextURL, setnextURL] = useSessionStorageState('next-url', '');
    amplitude.getInstance().logEvent('LIFF_LOGIN_START', {signinStatus, signInCheckResult, formId, contentId, liffState, organization, liffReady: ready, liffLoggedIn: isLoggedIn});

    useEffect(() => {
        (async () => {
            if(lineAccessToken && signinStatus === 'success' && !signInCheckResult.signedIn){
                amplitude.getInstance().logEvent('LIFF_LOGIN_CHECK_TOKEN_START')
                const login = functions.httpsCallable('lineLogin');
                await login({ lineAccessToken })
                amplitude.getInstance().logEvent('LIFF_LOGIN_CHECK_TOKEN_SUCCESS')
            }
        })()
    }, [lineAccessToken, functions, signinStatus, signInCheckResult]);


    if (ready && signinStatus === 'success') {
        if (signInCheckResult.signedIn === true) {
            amplitude.getInstance().logEvent('LIFF_LOGIN_FIREBASE_ALREADY_LOGIN')
            if (formId) {
                return <Redirect from="" to={`/${organization}/liff/form/${formId}`} noThrow />
            }
            if (contentId) {
                return <Redirect from="" to={`/${organization}/liff/content/${contentId}`} noThrow />
            }
            if (nextURL) {
                navigate(nextURL)
            }
            if (liffState) {
                let params = new URLSearchParams(decodeURIComponent(liffState))
                const liffFormId = params.get("formId")
                const liffContentId = params.get("contentId")
                const liffOrganization = params.get("organization")
                if (liffFormId) {
                    return <Redirect from="" to={`/${liffOrganization}/liff/form/${liffFormId}`} noThrow />
                }
                if (liffContentId) {
                    return <Redirect from="" to={`/${liffOrganization}/liff/content/${liffContentId}`} noThrow />
                }
            }
        }
        if (isLoggedIn) {
            amplitude.getInstance().logEvent('LIFF_LOGIN_SET_TOKEN')
            const accessToken = liff.getAccessToken();
            if (accessToken) {
                setLineAccessToken(accessToken)
            }
        } else {
            amplitude.getInstance().logEvent('LIFF_LOGIN_REDIRECT_LINE_LOGIN')
            liff.login();
        }
    }
    return <div>Line Login....</div>
}

export default LiffLogin;