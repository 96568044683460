import { RouteComponentProps, Link, navigate } from "@reach/router"
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Spin, ConfigProvider, Button } from 'antd';
import '@ant-design/pro-card/dist/card.css'
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import zhTWIntl from 'antd/lib/locale/zh_TW';
import { Analysis } from '../types/Analysis'
import { firestoreAutoId } from '../utils/uid'


export const AnalysisAdmin = (props: RouteComponentProps) => {
    const analysisRef = useFirestore().collection('analysis')
    const { data: analysisList, status } = useFirestoreCollectionData<Analysis>(analysisRef);

    const columns: ProColumns<Analysis>[] = [
        {
            title: '標題',
            dataIndex: 'displayName',
            width: '30%',
        },
        {
            title: '操作',
            valueType: 'option',
            width: 200,
            render: (text, record, _, action) => [
                <Link to={`${record.NO_ID_FIELD}`}>編輯</Link>
            ]
        },
    ];
    if (status !== 'success') {
        return (<Spin />)
    }

    return (
        <div>
            <Button onClick={() => navigate(`./analysis/${firestoreAutoId()}`)}>新增分析</Button>
            <ConfigProvider locale={zhTWIntl}>
                <ProTable<Analysis>
                    columns={columns}
                    // defaultData={(programs as any as Program[])}
                    request={(params, sorter, filter) => {
                        return Promise.resolve({
                            data: analysisList,
                            success: true,
                        });
                    }}
                    rowKey="NO_ID_FIELD"
                    pagination={{
                        showQuickJumper: true,
                    }}
                    search={false}
                    dateFormatter="string"
                    headerTitle="模組"
                    toolBarRender={false}
                />
            </ConfigProvider>
        </div>
    )
}