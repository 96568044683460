import { RouteComponentProps, Link, navigate } from "@reach/router"
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Spin, ConfigProvider, Button, message } from 'antd';
import '@ant-design/pro-card/dist/card.css'
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import zhTWIntl from 'antd/lib/locale/zh_TW';
import { firestoreAutoId } from '../utils/uid'
import { Content } from "../types/Content";
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';
import { LiffURL2, LIFFURL_CMUH } from "../config/liff";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const ContentsAdmin = (props: RouteComponentProps) => {
    const contentRef = useFirestore().collection('contents')
    const organization = useContext(OrganizationContext)

    const { data: contentList, status } = useFirestoreCollectionData<Content>(contentRef.where("organizationId", "==", organization.NO_ID_FIELD));

    const columns: ProColumns<Content>[] = [
        {
            title: '標題',
            dataIndex: 'title',
            width: '30%',
        },
        {
            title: '操作',
            valueType: 'option',
            width: 400,
            render: (text, record, _, action) => [
                <Link to={`${record.NO_ID_FIELD}`}>編輯</Link>,
                <a
                    key='preview'
                    href={`/${organization.name}/liff/content/${record.NO_ID_FIELD}?preview=1`}
                    target="_blank"
                    rel="noreferrer"
                >
                    預覽文章
                </a>,
                <CopyToClipboard text={`${LiffURL2}?contentId=${record.NO_ID_FIELD}&r=${Math.random().toString(36).substring(7)}`} onCopy={() => message.info("複製成功")}>
                    <a
                        key='generate'
                        href="#copy"
                    >
                        複製 Line 專用連結
                    </a>
                </CopyToClipboard>,
                <CopyToClipboard text={`${LIFFURL_CMUH}?contentId=${record.NO_ID_FIELD}&r=${Math.random().toString(36).substring(7)}`} onCopy={() => message.info("複製成功")}>
                    <a
                        key='generate'
                        href="#copy"
                    >
                        複製 Line 專用連結 (cmuh)
                    </a>
                </CopyToClipboard>
            ]
        },
    ];
    if (status !== 'success') {
        return (<Spin />)
    }

    return (
        <div>
            <Button onClick={() => navigate(`./contents/${firestoreAutoId()}`)}>新增文章</Button>
            <ConfigProvider locale={zhTWIntl}>
                <ProTable<Content>
                    columns={columns}
                    request={(params, sorter, filter) => {
                        return Promise.resolve({
                            data: contentList,
                            success: true,
                        });
                    }}
                    rowKey="NO_ID_FIELD"
                    pagination={{
                        showQuickJumper: true,
                    }}
                    search={false}
                    dateFormatter="string"
                    headerTitle="文章"
                    toolBarRender={false}
                />
            </ConfigProvider>
        </div>
    )
}