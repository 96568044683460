import { RouteComponentProps } from "@reach/router"
import { useUser } from 'reactfire';
import { useFirestoreDocData, useFirestore } from 'reactfire';
import FormRender, { useForm } from 'form-render';
import { firestoreAutoId } from '../utils/uid'

export const Manage = (props: RouteComponentProps) => {
    const { data: user } = useUser();
    const userDataRef = useFirestore().collection('users').doc(user.uid)
    const { data: userData, status: userDataStatus } = useFirestoreDocData(userDataRef)
    const organizationId = userData ? (userData as any).organizationId : firestoreAutoId()
    const organizationRef = useFirestore().collection('organizations').doc(organizationId)
    const { data: organizationData, status: organizationDataStatus } = useFirestoreDocData(organizationRef)


    const schema = {
        "type": "object",
        "properties": {
            "name": {
                "title": "姓名",
                "required": false,
                "disabled": false,
                "readOnly": false,
                "hidden": false,
                "props": {
                    "allowClear": false
                },
                "type": "string"
            },
            "lineUserId": {
                "title": "Line uid",
                "required": false,
                "disabled": false,
                "readOnly": true,
                "hidden": false,
                "props": {
                    "allowClear": false
                },
                "type": "string"
            },
            "organizationName": {
                "title": "組織名稱",
                "required": false,
                "disabled": false,
                "readOnly": true,
                "hidden": false,
                "props": {
                    "allowClear": false
                },
                "type": "string"
            }
        },
        "displayType": "row"
    }
    const form = useForm();
    const onMount = () => {
        form.setValues({ ...(userData as object), organizationName: (organizationData as any).name })
    }
    if (userDataStatus === 'success' && organizationDataStatus === 'success') {
        return (
            <div>
                <FormRender form={form} schema={schema} onMount={onMount} />
            </div>
        )
    } else {
        return (<div>loading</div>)
    }

}