
import { Router, RouteComponentProps, Redirect } from "@reach/router"
import UserForm from './public/UserForm';
import UserContent from './public/UserContent';
import { Layout, Spin } from 'antd';
import { useLiff } from 'react-liff';
import { useQuery } from './hooks'
import { useSigninCheck } from 'reactfire';
import { useSessionStorageState } from 'ahooks';
import { useEffect } from "react";
import amplitude from 'amplitude-js';
import { OrganizationContext } from './context/Organization';
import { useContext } from 'react';
import { version } from '../package.json';

const { Content, Footer } = Layout;

export const Public = (props: RouteComponentProps) => {
    const [nextURL, setnextURL] = useSessionStorageState('next-url', '');
    const [organizationStorage, setOrganizationStorage] = useSessionStorageState('organization', '');
    const [organizationIdStorage, setOrganizationIdStorage] = useSessionStorageState('organizationId', '');
    const { isLoggedIn, ready } = useLiff();
    const preview = useQuery('preview') || null;
    const { status, data: signInCheckResult } = useSigninCheck();
    const organization = useContext(OrganizationContext)

    useEffect(() => {
        setnextURL(props.location ? props.location.href : "")
    }, [props.location, props.location?.href, setnextURL])

    useEffect(() => {
        setOrganizationIdStorage(organization.NO_ID_FIELD)
        setOrganizationStorage(organization.name)
    }, [organization, setOrganizationStorage])

    if (status === 'loading') {
        return (<Spin tip="爾科正在準備喔"/>)
    }
    if ((signInCheckResult.signedIn === true)|| preview) {
        if(signInCheckResult.user)
            amplitude.getInstance().setUserId(signInCheckResult.user.uid);
        return (
            <Layout className="layout">
                <Content>
                    <Router>
                        <UserForm path="form/:formId" formId="" />
                        <UserContent path="content/:contentId" contentId="" />
                    </Router>
                </Content>
                <Footer style={{ textAlign: 'center' }}>v{version} | 爾科有限公司 ErrkHealth.com ©2021</Footer>
            </Layout>
        )
    }
    // return <div>test</div>
    return <Redirect from="" to="/liff-login" noThrow />
}

