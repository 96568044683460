import 'firebase/firestore';
import { useFirestoreDocData, useFirestore, useFirestoreCollectionData, firestore } from 'reactfire';
import { Row, Button, Typography, Skeleton, message, Spin } from 'antd';
import { useUser } from 'reactfire';
import FormRender, { Error, useForm } from 'form-render';
import Mustache from 'mustache';
import { evaluate, isNumeric, round } from 'mathjs'
import { useState } from 'react'
import { FormResult, FormResultInput } from '../types/FormResult'
import { Form } from '../types/Form'
import { UserFormProps } from "../types/Route"
import { OrganizationContext } from '../context/Organization';
import { useContext } from 'react';
import amplitude from 'amplitude-js';
import { UserTimeRelatedData, UserTimeRelatedInput } from '../types/UserTimeRelatedData';
import { firestoreAutoId } from "../utils/uid";
import { useLiff } from 'react-liff';

const { Title } = Typography;

const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const UserForm = (props: UserFormProps) => {
    const { liff } = useLiff();
    const { data: user } = useUser();
    const organization = useContext(OrganizationContext)
    const [submitLoading, setSubmitLoading] = useState(false)
    const formIdRef = `/forms/${props.formId}`
    const formRef = useFirestore()
        .collection('forms')
        .doc(props.formId);
    const formResultRef = useFirestore().collection('formResults')
    const uid = user ? user.uid : "fakeUid";
    const q = formResultRef.where("uid", "==", uid).where("formRef", "==", formIdRef)

    const { data: existedFormResults, status: existFromResultsStatus } = useFirestoreCollectionData<FormResult>(q);
    const existedFormResult = existedFormResults ? existedFormResults[0] : undefined;

    const updateFormId = existedFormResult ? existedFormResult.NO_ID_FIELD : firestoreAutoId();
    const formResultUpdateRef = useFirestore().collection('formResults').doc(updateFormId);

    const { status, data } = useFirestoreDocData<Form>(formRef);
    const form = useForm();

    const userPropertiesRef = useFirestore().collection('userProperties').doc(`${uid}+${organization.NO_ID_FIELD}`)
    const userTimeRelatedDataRef = useFirestore().collection('userTimeRelatedData')

    if (status === 'loading' || props.formId === undefined) {
        return (
            <Row justify="center" style={{ minHeight: '85vh' }}>
                <Spin tip="努力讀取問卷" />
            </Row>
        )
    }

    const eventProperties = {
        formId: props.formId,
        formTitle: data.title,
        organizationId: organization.NO_ID_FIELD,
        organizationName: organization.name,
    }

    const schema = data && data.schema ? JSON.parse(data.schema) : {}
    const title = data.title

    const getAllFormData = (obj: object, res: { [k: string]: string | number }) => {
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'number' || typeof value === 'string') {
                res[(key as string)] = value;
            } else if (typeof value === 'object') {
                getAllFormData(value, res);
            }
        }
    }

    const watch = {
        '#': (val: any) => {
            for (const [key, value] of Object.entries(form.flatten)) {
                let calculated = (value as any).schema.calculated as string
                const roundNumber = (value as any).schema.roundNumber as number
                const visibleOp = (value as any).schema.visibleOp as ">" | "=" | "<" | "~"
                const visibleValue = (value as any).schema.visibleValue as number
                const visibleValue2 = (value as any).schema.visibleValue2 as number
                const widget = (value as any).schema.widget as string
                const hidden = (value as any).schema.hidden || false as boolean
                if (calculated) {
                    calculated = calculated.replace(/(\r\n|\n|\r)/gm, "");
                    try {
                        let data = {};
                        getAllFormData(form.formData, data)
                        const formula = Mustache.render(calculated, data, {}, ['${', '}$'])
                        let result = evaluate(formula);
                        console.log({
                            key,
                            formula,
                            result,
                            visibleOp,
                            visibleValue,
                            visibleValue2
                        })
                        if (isNumeric(roundNumber)) {
                            result = round(result, roundNumber)
                        }
                        if (widget !== "html")
                            form.setValueByPath(key, result);
                        if (visibleOp && visibleValue !== undefined) {
                            const lt = visibleOp === "<" && result < visibleValue;
                            const eq = visibleOp === "=" && result === visibleValue;
                            const gt = visibleOp === ">" && result > visibleValue;
                            const it = visibleOp === "~" && visibleValue2 && result >= visibleValue && result <= visibleValue2
                            if (lt)
                                console.log(key, result, "<", visibleValue)
                            if (eq)
                                console.log(key, result, "=", visibleValue)
                            if (gt)
                                console.log(key, result, ">", visibleValue)
                            if (it)
                                console.log(key, visibleValue, "<=", result, "<=", visibleValue2)
                            if (lt || eq || gt || it) {
                                if(hidden)
                                    setTimeout(
                                        () => form.setSchemaByPath(key, { hidden: false })
                                        , getRandomInt(500, 1000));
                                } else {
                                    if(!hidden)
                                        setTimeout(
                                            () => form.setSchemaByPath(key, { hidden: true })
                                            , getRandomInt(100, 500));
                            }
                        }
                    } catch (e){
                        // console.log(e)
                        // message.error("表單算式錯誤")
                    }
                }
            }
        },
    };

    const onMount = () => {
        form.setValues(existedFormResult?.result);
    }

    const onFinish = async (data: any, error: Error[]) => {
        if (error.length > 0) {
            message.warning("問卷沒有填寫完喔！")
            console.log(error)
            return
        }
        const result: FormResultInput = {
            formRef: formIdRef,
            result: data,
            uid: user.uid,
            organizationId: organization.NO_ID_FIELD as string,
            formId: props.formId as string
        };
        setSubmitLoading(true)
        let userProperties: { [key: string]: any } = {}
        let userTimeRelatedDataList: UserTimeRelatedInput[] = []
        let recordDate = data?.recordDate
        try {
            recordDate = new Date(recordDate)
        } catch {
            recordDate = undefined
        }
        for (const [key, value] of Object.entries(data)) {
            const userPropertyKey = form.flatten[key].schema.userPropertyKey
            const userTimeRelatedDataKey = form.flatten[key].schema.userTimeRelatedDataKey
            const userTimeRelatedDataType = form.flatten[key].schema.type
            if (userPropertyKey) {
                userProperties[userPropertyKey] = value
            }
            if (userTimeRelatedDataKey) {
                userTimeRelatedDataList.push({
                    typeKey: userTimeRelatedDataKey,
                    value: value,
                    valueType: userTimeRelatedDataType,
                    recordTime: recordDate || new Date(),
                    createAt: new Date()
                })
            }
        }
        try {
            await formResultUpdateRef.set(result)
            await userPropertiesRef.set({ ...userProperties, uid, organizationId: organization.NO_ID_FIELD }, { merge: true })
            await Promise.all(userTimeRelatedDataList.map((data) => {
                return userTimeRelatedDataRef.doc().set({ ...data, uid, organizationId: organization.NO_ID_FIELD, formId: props.formId, formResultRef: updateFormId })
            })
            )
            message.success("成功")
            amplitude.getInstance().logEvent('USER_FORM_SUBMIT_SUCCESS', eventProperties);
            setTimeout(() => liff.closeWindow(), 1000)
        } catch {
            message.error("出了一點錯誤~")
            amplitude.getInstance().logEvent('USER_FORM_SUBMIT_FAILED', eventProperties);
        }
        setSubmitLoading(false)
    }

    if (existFromResultsStatus !== 'success') {
        return <Spin tip="讀取上次填寫問卷紀錄"></Spin>
    }

    amplitude.getInstance().logEvent('USER_FORM_START', eventProperties);

    return (
        <div style={{ padding: '15px', backgroundColor: 'white', minHeight: '100vh', maxWidth: '500px', margin: '0 auto' }}>
            <Title level={4}>{title}</Title>
            <FormRender form={form} schema={schema} watch={watch} onFinish={onFinish} onMount={onMount} />
            <Button type="primary" onClick={form.submit} loading={submitLoading}>
                送出
            </Button>
        </div>

    )
}

export default UserForm;