import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { RouteComponentProps, navigate } from "@reach/router"
import { Organization } from '../types/Organization';
import { Button, Card, Form, Select, Spin, Typography } from 'antd';

const { Option } = Select;
const { Title } = Typography;

export const Prelogin = (props: RouteComponentProps) => {

    const organizationsRef = useFirestore()
        .collection('organizations')

    const { status, data: organizations } = useFirestoreCollectionData<Organization>(organizationsRef);
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const onFinish = async (values: any) => {
        navigate(`${values.organizationName}/admin-login`)
    };
    if (status !== 'success') {
        return <Spin />
    }
    return (
        <div style={{ padding: '15px', backgroundColor: 'white', minHeight: '100vh', maxWidth: '500px', margin: '0 auto' }}>
            <Card style={{ width: "auto" }}>
                <div style={{ textAlign: "center" }}>
                    <Title level={4}>爾科系統管理員登入</Title>
                </div>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="organizationName"
                        label="登入機構"
                        rules={[{ required: true, message: '請選擇登入機構' }]}>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="請選擇登入機構"
                        >
                            {organizations.map(organization => <Option value={organization.name}>{organization.displayName}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" >
                            登入
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}