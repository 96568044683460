export const userPropertiresTreeData = [
    {
        "title": "基本資料",
        "value": "basic",
        "key": "basic",
        "selectable": false,
        "children": [
            {
                "title": "姓名",
                "value": "name",
                "key": "name"
            },
            {
                "title": "手機號碼",
                "value": "phone",
                "key": "phone"
            },
            {
                "title": "電子郵件",
                "value": "email",
                "key": "email"
            },
            {
                "title": "Line ID",
                "value": "lineID",
                "key": "lineID"
            }
        ]
    },
    {
        "title": "基本身體數據",
        "value": "basicBody",
        "key": "basicBody",
        "selectable": false,
        "children": [
            {
                "title": "身高",
                "value": "height",
                "key": "height"
            },
            {
                "title": "體重",
                "value": "weight",
                "key": "weight"
            },
            {
                "title": "性別",
                "value": "sex",
                "key": "sex"
            },
            {
                "title": "生日",
                "value": "birthday",
                "key": "birthday"
            },
            {
                "title": "活動強度",
                "value": "activityLevel",
                "key": "activityLevel"
            },
            {
                "title": "休息心率",
                "value": "restingHR",
                "key": "restingHR"
            },
            {
                "title": "體重目標",
                "value": "targetBW",
                "key": "targetBW"
            }
        ]
    },
    {
        "title": "過去病史",
        "value": "PMH",
        "key": "PMH",
        "selectable": false,
        "children": [
            {
                "title": "糖尿病",
                "value": "DM",
                "key": "DM"
            },
            {
                "title": "高血壓",
                "value": "HTN",
                "key": "HTN"
            },
            {
                "title": "心血管疾病",
                "value": "CVD",
                "key": "CVD"
            },
            {
                "title": "黑色棘皮症",
                "value": "acanthosis",
                "key": "acanthosis"
            },
            {
                "title": "其他疾病",
                "value": "otherPMH",
                "key": "otherPMH"
            },
            {
                "title": "多囊性卵巢症候群",
                "value": "PCOS",
                "key": "PCOS"
            },
            {
                "title": "妊娠糖尿病",
                "value": "GDM",
                "key": "GDM"
            }
        ]
    },
    {
        "title": "家族史",
        "value": "FHx",
        "key": "FHx",
        "selectable": false,
        "children": [
            {
                "title": "糖尿病家族史",
                "value": "DMFHx",
                "key": "DMFHx"
            },
            {
                "title": "心血管疾病家族史",
                "value": "CVDFHx",
                "key": "CVDFHx"
            }
        ]
    },
    {
        "title": "生活型態",
        "value": "lifeStyle",
        "key": "lifeStyle",
        "selectable": false,
        "children": [
            {
                "title": "抽菸",
                "value": "cigarette",
                "key": "cigarette"
            },
            {
                "title": "喝酒",
                "value": "alcohol",
                "key": "alcohol"
            },
            {
                "title": "久坐",
                "value": "sedentary",
                "key": "sedentary"
            }
        ]
    },
    {
        "title": "檢驗_心血管系統",
        "value": "lab_CV",
        "key": "lab_CV",
        "selectable": false,
        "children": [
            {
                "title": "腰圍",
                "value": "circumference",
                "key": "circumference"
            },
            {
                "title": "收縮壓",
                "value": "SBP",
                "key": "SBP"
            },
            {
                "title": "舒張壓",
                "value": "DBP",
                "key": "DBP"
            },
            {
                "title": "總膽固醇",
                "value": "TC",
                "key": "TC"
            },
            {
                "title": "三酸甘油脂",
                "value": "TG",
                "key": "TG"
            },
            {
                "title": "高密度脂蛋白膽固醇",
                "value": "HDL",
                "key": "HDL"
            },
            {
                "title": "低密度脂蛋白膽固醇",
                "value": "LDL",
                "key": "LDL"
            }
        ]
    },
    {
        "title": "檢驗_內分泌系統",
        "value": "lab_endocrine",
        "key": "lab_endocrine",
        "selectable": false,
        "children": [
            {
                "title": "空腹血糖",
                "value": "fastingBS",
                "key": "fastingBS"
            },
            {
                "title": "糖化血色素",
                "value": "HbA1C",
                "key": "HbA1C"
            }
        ]
    },
    {
        "title": "檢驗_免疫系統",
        "value": "lab_immune",
        "key": "lab_immune",
        "selectable": false,
        "children": [
            {
                "title": "尿酸",
                "value": "uricAcid",
                "key": "uricAcid"
            },
            {
                "title": "C 反應性蛋白",
                "value": "CRP",
                "key": "CRP"
            },
            {
                "title": "血清鐵蛋白原",
                "value": "ferritin",
                "key": "ferritin"
            }
        ]
    },
    {
        "title": "檢驗_肝膽腸胃系統",
        "value": "lab_GI",
        "key": "lab_GI",
        "selectable": false,
        "children": [
            {
                "title": "γ-麩胺酸轉化酶",
                "value": "GGT",
                "key": "GGT"
            }
        ]
    },
    {
        "title": "功能醫學_整體",
        "value": "fm_general",
        "key": "fm_general",
        "selectable": false,
        "children": [
            {
                "title": "身體變差",
                "value": "generalFeeling",
                "key": "generalFeeling"
            },
            {
                "title": "體重變化",
                "value": "BWChange",
                "key": "BWChange"
            },
            {
                "title": "失眠",
                "value": "insomnia",
                "key": "insomnia"
            },
            {
                "title": "肌肉骨骼問題",
                "value": "mskProblem",
                "key": "mskProblem"
            },
            {
                "title": "疲勞",
                "value": "fatigue",
                "key": "fatigue"
            },
            {
                "title": "焦慮",
                "value": "anxiety",
                "key": "anxiety"
            },
            {
                "title": "記憶力問題",
                "value": "memory",
                "key": "memory"
            },
            {
                "title": "耳鳴",
                "value": "tinnitus",
                "key": "tinnitus"
            },
            {
                "title": "體力",
                "value": "spirit",
                "key": "spirit"
            },
            {
                "title": "處方藥",
                "value": "prescriptionDrug",
                "key": "prescriptionDrug"
            },
            {
                "title": "成藥",
                "value": "overcornerDrug",
                "key": "overcornerDrug"
            },
            {
                "title": "過敏",
                "value": "allergy",
                "key": "allergy"
            },
            {
                "title": "注意力問題",
                "value": "inattention",
                "key": "inattention"
            },
            {
                "title": "呼吸困難",
                "value": "dyspnea",
                "key": "dyspnea"
            },
            {
                "title": "味嗅覺問題",
                "value": "odor",
                "key": "odor"
            },
            {
                "title": "肌肉量問題",
                "value": "muscleLoss",
                "key": "muscleLoss"
            },
            {
                "title": "三高",
                "value": "cvdRisk",
                "key": "cvdRisk"
            },
            {
                "title": "牙齒問題",
                "value": "dental",
                "key": "dental"
            },
            {
                "title": "腸胃道問題",
                "value": "GI",
                "key": "GI"
            },
            {
                "title": "口臭",
                "value": "badBreath",
                "key": "badBreath"
            },
            {
                "title": "鈣化",
                "value": "calcification",
                "key": "calcification"
            },
            {
                "title": "免疫問題",
                "value": "immune",
                "key": "immune"
            }
        ]
    },
    {
        "title": "運動前篩檢",
        "value": "fm_general",
        "key": "fm_general",
        "selectable": false,
        "children": [
            {
                "title": "身體不活動",
                "value": "PARQ_physicalInactivity",
                "key": "PARQ_physicalInactivity"
            },
            {
                "title": "心臟疾病",
                "value": "PARQ_heartDz",
                "key": "PARQ_heartDz"
            },
            {
                "title": "慢性疾病",
                "value": "PARQ_chronicDz",
                "key": "PARQ_chronicDz"
            },
            {
                "title": "處方藥",
                "value": "PARQ_prescriptionDrug",
                "key": "PARQ_prescriptionDrug"
            },
            {
                "title": "胸痛",
                "value": "PARQ_chestPain",
                "key": "PARQ_chestPain"
            },
            {
                "title": "頭暈",
                "value": "PARQ_dizziness",
                "key": "PARQ_dizziness"
            },
            {
                "title": "肌肉骨骼問題",
                "value": "PARQ_MSKProblem",
                "key": "PARQ_MSKProblem"
            },
            {
                "title": "運動監督",
                "value": "PARQ_exerciseSupervision",
                "key": "PARQ_exerciseSupervision"
            },
            {
                "title": "心臟腎臟代謝疾病相關症狀",
                "value": "PARQ_CMRSymptom",
                "key": "PARQ_CMRSymptom"
            }
        ]
    },
    {
        "title": "運動前篩檢_過去病史",
        "value": "fm_general",
        "key": "fm_general",
        "selectable": false,
        "children": [
            {
                "title": "關節肌肉骨骼",
                "value": "PARQ_arthritis",
                "key": "PARQ_arthritis"
            },
            {
                "title": "癌症",
                "value": "PARQ_cancer",
                "key": "PARQ_cancer"
            },
            {
                "title": "心臟疾病",
                "value": "PARQ_heartDz",
                "key": "PARQ_heartDz"
            },
            {
                "title": "高血壓",
                "value": "PARQ_HTN",
                "key": "PARQ_HTN"
            },
            {
                "title": "代謝問題",
                "value": "PARQ_metabolic",
                "key": "PARQ_metabolic"
            },
            {
                "title": "心理健康",
                "value": "PARQ_mental",
                "key": "PARQ_mental"
            },
            {
                "title": "呼吸系統",
                "value": "PARQ_respiratory",
                "key": "PARQ_respiratory"
            },
            {
                "title": "脊髓損傷",
                "value": "PARQ_SCI",
                "key": "PARQ_SCI"
            },
            {
                "title": "中風",
                "value": "PARQ_CVA",
                "key": "PARQ_CVA"
            },
            {
                "title": "其他疾病",
                "value": "PARQ_otherDz",
                "key": "PARQ_otherDz"
            }
        ]
    }
]
