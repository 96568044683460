import 'firebase/firestore';
import { useFirestoreDocData, useFirestore } from 'reactfire';
import { Row, Button, Typography, Skeleton, message, Spin } from 'antd';
import FormRender, { Error, useForm } from 'form-render';
import Mustache from 'mustache';
import { evaluate, isNumeric, round } from 'mathjs'
import { useState } from 'react'
import { FormResult } from '../types/FormResult'
import { Form } from '../types/Form'
import { FormResultsDetailAdminProps } from "../types/Route"
import { firestoreAutoId } from '../utils/uid';

const { Title } = Typography;


const FormResultsDetailAdmin = (props: FormResultsDetailAdminProps) => {
    const [submitLoading, setSubmitLoading] = useState(false)
    const r = useFirestore().collection("formResults").doc(props.formResultId)
    const { data: existedFormResult, status: existFromResultsStatus } = useFirestoreDocData<FormResult>(r);

    const formRef = useFirestore()
        .collection('forms')
        .doc(existedFormResult?.formId || firestoreAutoId());

    const updateFormId = existedFormResult ? existedFormResult.NO_ID_FIELD : undefined
    const formResultUpdateRef = useFirestore().collection('formResults').doc(updateFormId);

    const { status, data } = useFirestoreDocData<Form>(formRef);
    const form = useForm();

    const userPropertiesRef = useFirestore().collection('userProperties').doc(existedFormResult?.uid || firestoreAutoId())

    if (status === 'loading') {
        return (
            <Row justify="center" style={{ minHeight: '100vh' }}>
                <Skeleton />
            </Row>
        )
    }

    const schema = data && data.schema ? JSON.parse(data.schema) : {}
    const title = data.title

    const watch = {
        '#': (val: any) => {
            for (const [key, value] of Object.entries(schema.properties)) {
                const calculated = (value as any).calculated as string
                const roundNumber = (value as any).roundNumber as number
                if (calculated) {
                    try {
                        const formula = Mustache.render(calculated, form.formData)
                        let result = evaluate(formula);
                        if (isNumeric(roundNumber)) {
                            result = round(result, roundNumber)
                        }
                        form.setValueByPath(key, result);
                    } catch {
                        // message.error("表單算式錯誤")
                    }
                }
            }
        },
    };

    const onMount = () => {
        form.setValues(existedFormResult?.result);
    }

    const onFinish = async (data: any, error: Error[]) => {
        setSubmitLoading(true)
        let userProperties: { [key: string]: any } = {}
        for (const [key, value] of Object.entries(data)) {
            const userPropertyKey = form.flatten[key].schema.userPropertyKey
            if (userPropertyKey) {
                userProperties[userPropertyKey] = value
            }
        }
        userProperties.uid = userProperties.uid || existedFormResult.uid
        userProperties.organizationId = userProperties.organizationId || existedFormResult.organizationId
        try {
            await formResultUpdateRef.update({
                result: data,
            })
            await userPropertiesRef.set(userProperties, { merge: true })
            message.success("成功")
        } catch {
            message.error("出了一點錯誤~")
        }
        setSubmitLoading(false)
    }

    if (existFromResultsStatus !== 'success') {
        return <Spin></Spin>
    }

    return (
        <div style={{ padding: '15px', backgroundColor: 'white', minHeight: '100vh', maxWidth: '500px', margin: '0 auto' }}>
            <Title level={4}>{title}</Title>
            <span>uid: {existedFormResult.uid}</span>
            <FormRender form={form} schema={schema} watch={watch} onFinish={onFinish} onMount={onMount} />
            <Button type="primary" onClick={form.submit} loading={submitLoading}>
                更新
            </Button>
        </div>

    )
}

export default FormResultsDetailAdmin;