import { RouteComponentProps } from "@reach/router"
import 'firebase/firestore';
import { useFirestore, useFirestoreDocData, useFirestoreCollectionData } from 'reactfire';
import '@ant-design/pro-card/dist/card.css'
import FormRender, { Error, useForm } from 'form-render';
import { Typography, Table, Spin, Button, message, Divider, Space, Row, Col, Card } from 'antd';
import { useState } from 'react';
import { ColumnsType } from "antd/lib/table";
import { Form } from "../types/Form"
import { ProgramSession, Program } from '../types/Program'
import { OrganizationContext } from '../context/Organization';
import { useContext } from "react";
const { Title } = Typography;

interface ProgramDetailAdminProps extends RouteComponentProps {
  programId?: string;
}

export const ProgramDetailAdmin = (props: ProgramDetailAdminProps) => {
  const organization = useContext(OrganizationContext);
  const programUpdateRef = useFirestore().collection('programs').doc(props.programId)
  const program = useFirestoreDocData<Program>(programUpdateRef);
  const formsRef = useFirestore().collection('forms')
  const { data: forms, status: formStatus } = useFirestoreCollectionData<Form>(formsRef);
  const [sessionSelect, setSessionSelect] = useState(0)
  const [mainFormLoading, setMainFormLoading] = useState(false)
  const [sessionFormLoading, setSessionFormLoading] = useState(false)
  const [createSessionLoading, setCreateSessionLoading] = useState(false)

  const mainSchema = {
    "type": "object",
    "properties": {
      "displayName": {
        "title": "模組名稱",
        "type": "string",
        "required": true,
        "disabled": false,
        "readOnly": false,
        "hidden": false,
        "props": {
          "allowClear": false
        }
      },
      "totalDay": {
        "title": "總天數",
        "type": "number",
        "required": true,
        "min": 0,
        "disabled": false,
        "readOnly": false,
        "hidden": false
      }
    },
    "displayType": "row"
  }

  const sessionSchema = {
    "type": "object",
    "displayType": "row",
    "labelWidth": 120,
    "properties": {
      "triggerDay": {
        "title": "天數",
        "required": false,
        "disabled": false,
        "readOnly": false,
        "hidden": false,
        "type": "number"
      },
      "triggerTime": {
        "title": "觸發時間",
        "type": "string",
        "enum": [
          "9:00",
          "12:00",
          "18:00"
        ],
        "enumNames": [
          "9:00",
          "12:00",
          "18:00"
        ],
        "widget": "select"
      },
      "description": {
        "title": "備註",
        "type": "string",
        "required": false,
        "disabled": false,
        "readOnly": false,
        "hidden": false,
        "props": {
          "allowClear": false
        }
      },
      "sessionList": {
        "title": "模組列表",
        "required": false,
        "disabled": false,
        "readOnly": false,
        "hidden": false,
        "props": {
          "foldable": false,
          "addBtnProps": {
            "children": "新增模組"
          }
        },
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "messageIndex": {
              "title": "訊息編號",
              "required": false,
              "disabled": false,
              "readOnly": true,
              "hidden": false,
              "width": "20%",
              "props": {
                "allowClear": false
              },
              "type": "string"
            },
            "attribute": {
              "title": "狀態",
              "type": "array",
              "widget": "checkboxes",
              "items": {
                "type": "string"
              },
              "enum": [
                "isSessionFinish",
                "isRequired"
              ],
              "enumNames": [
                "結束",
                "必要"
              ]
            },
            "messageFrom": {
              "title": "使用者",
              "required": true,
              "disabled": false,
              "readOnly": false,
              "hidden": false,
              "width": "100%",
              "enum": [
                "system",
                "user"
              ],
              "enumNames": [
                "系統",
                "使用者"
              ],
              "type": "string",
              "widget": "select"
            },
            "systemMessageType": {
              "title": "訊息種類",
              "required": false,
              "disabled": false,
              "readOnly": false,
              "hidden": "{{rootValue.messageFrom !== 'system'}}",
              "width": "20%",
              "enum": [
                "text",
                "image",
                "flex"
              ],
              "enumNames": [
                "文字",
                "圖片",
                "Flex"
              ],
              "type": "string",
              "widget": "select"
            },
            "systemMessageTextContent": {
              "title": "文字內容",
              "required": false,
              "disabled": false,
              "readOnly": false,
              "format": "textarea",
              "hidden": "{{rootValue.messageFrom !== 'system' || rootValue.systemMessageType !== 'text'}}",
              "props": {
                "allowClear": false
              },
              "type": "string"
            },
            "systemMessageImageContent": {
              "title": "圖片網址",
              "required": false,
              "disabled": false,
              "readOnly": false,
              "hidden": "{{rootValue.messageFrom !== 'system' || rootValue.systemMessageType !== 'image'}}",
              "props": {
                "allowClear": false
              },
              "type": "string"
            },
            "systemMessageFlexContent": {
              "title": "Flex 訊息",
              "type": "string",
              "format": "textarea",
              "hidden": "{{rootValue.messageFrom !== 'system' || rootValue.systemMessageType !== 'flex'}}"
            },
            "systemNextIndex": {
              "title": "下則訊息",
              "required": false,
              "disabled": false,
              "readOnly": false,
              "width": "20%",
              "enum": [],
              "enumNames": [],
              "type": "string",
              "widget": "select",
              "hidden": "{{rootValue.messageFrom !== 'system'}}"
            },
            "userReplyList": {
              "title": "回應選項",
              "type": "array",
              "props": {
                "addBtnProps": {
                  "children": "新增回應選項"
                }
              },
              "hidden": "{{rootValue.messageFrom !== 'user'}}",
              "items": {
                "type": "object",
                "properties": {
                  "userMessageReplyType": {
                    "title": "回應種類",
                    "required": true,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": false,
                    "width": "40%",
                    "enum": [
                      "text",
                      "url",
                      "form"
                    ],
                    "enumNames": [
                      "文字",
                      "網址",
                      "問卷"
                    ],
                    "type": "string",
                    "widget": "select"
                  },
                  "userMessageTextContent": {
                    "title": "回應內容",
                    "required": false,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": "{{rootValue.userMessageReplyType !== 'text'}}",
                    "props": {
                      "allowClear": false
                    },
                    "type": "string"
                  },
                  "userMessageUrl": {
                    "title": "回應網址",
                    "required": false,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": "{{rootValue.userMessageReplyType !== 'url'}}",
                    "props": {
                      "allowClear": false
                    },
                    "type": "string"
                  },
                  "userMessageUrlDisplay": {
                    "title": "顯示文字",
                    "required": false,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": "{{rootValue.userMessageReplyType !== 'url'}}",
                    "props": {
                      "allowClear": false
                    },
                    "type": "string"
                  },
                  "userMessageForm": {
                    "title": "問卷",
                    "type": "string",
                    "widget": "select",
                    "required": false,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": "{{rootValue.userMessageReplyType !== 'form'}}",
                    "enum": [],
                    "enumNames": []
                  },
                  "userMessageFormDisplay": {
                    "title": "顯示文字",
                    "required": false,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": "{{rootValue.userMessageReplyType !== 'form'}}",
                    "props": {
                      "allowClear": false
                    },
                    "type": "string"
                  },
                  "userNextIndex": {
                    "title": "下則訊息",
                    "required": false,
                    "disabled": false,
                    "readOnly": false,
                    "hidden": false,
                    "width": "20%",
                    "enum": [],
                    "enumNames": [],
                    "type": "string",
                    "widget": "select"
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  const watch = {
    'sessionList': (sessionList: any[]) => {
      let systemCount = 0;
      let userCount = 0;
      let count = 0
      let indexList: string[] = []
      let systemIndexList: string[] = []
      if (!sessionList)
        sessionList = []
      for (const session of sessionList) {
        if (session.messageIndex) {
          indexList.push(session.messageIndex)
          if (session.messageIndex.startsWith('S')) {
            systemIndexList.push(session.messageIndex)
          }
        }
        if (session.messageFrom === 'system') {
          systemCount++;
          let systemMessageIndex = `S${systemCount}`
          if (session.messageIndex !== systemMessageIndex) {
            sessionForm.setValueByPath(`sessionList.${count}.messageIndex`, systemMessageIndex);
            break;
          }
        } else if (session.messageFrom === 'user') {
          userCount++;
          let userMessageIndex = `U${userCount}`
          if (session.messageIndex !== userMessageIndex) {
            sessionForm.setValueByPath(`sessionList.${count}.messageIndex`, userMessageIndex);
            break;
          }
        }
        count++;
      }
      const systemNextIndex = 'sessionList[].systemNextIndex'
      const userNextIndex = `sessionList[].userReplyList[].userNextIndex`
      if (sessionForm.flatten[userNextIndex].schema.enum !== systemIndexList) {
        sessionForm.setSchemaByPath(userNextIndex, { enumNames: systemIndexList, enum: systemIndexList });
      }
      if (sessionForm.flatten[systemNextIndex].schema.enum !== indexList) {
        setTimeout(
          () => sessionForm.setSchemaByPath(systemNextIndex, { enumNames: indexList, enum: indexList })
          , 500);
      }
    }
  }

  const columns: ColumnsType<ProgramSession> = [
    {
      title: 'ID',
      dataIndex: 'index',
      key: 'index',
      render: (value, record, index) => {
        return index
      },
      width: 30
    },
    {
      title: '天數',
      dataIndex: ['triggerDay'],
      key: 'triggerDay',
      width: 100
    },
    {
      title: '觸發時間',
      dataIndex: ['triggerTime'],
      key: 'triggerTime',
      width: 100
    },
    {
      title: '備註',
      dataIndex: ['description'],
      key: 'description',
      width: 500
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record, index) => (
        <Space size="middle">
          <Button onClick={() => {
            setSessionSelect(index)
            const s = program.data.sessions[index]
            sessionForm.setValues(s)
          }}>編輯</Button>
        </Space>
      ),
    },
  ];


  const mainForm = useForm()
  const sessionForm = useForm();

  const mainFormOnMount = () => {
    mainForm.setValues({
      "displayName": program.data.displayName,
      "totalDay": program.data.totalDay
    })
  }

  const mainFormOnFinish = async (data: any, errors: Error[]) => {
    setMainFormLoading(true)
    await programUpdateRef.set({
      "displayName": data.displayName,
      "totalDay": data.totalDay,
      "organizationId": organization.NO_ID_FIELD
    }, { merge: true })
    setMainFormLoading(false)
    message.success("更新成功")
  }

  const sessionFormOnMount = () => {
    if (program.data.sessions === undefined) {
      return
    }
    const s = program.data.sessions[sessionSelect]
    sessionForm.setValues(s)
    const enumList = forms.map(form => form.NO_ID_FIELD)
    const enumNameList = forms.map(form => form.title)
    sessionForm.setSchemaByPath('sessionList[].userReplyList[].userMessageForm', {
      enum: enumList,
      enumNames: enumNameList,
    });
  }

  const sessionFormOnFinish = async (data: any, errors: Error[]) => {
    setSessionFormLoading(true)
    let newSessions = []
    if (program.data.sessions) {
      newSessions = [...program.data.sessions]
      newSessions[sessionSelect] = data
    } else {
      newSessions = [data]
    }
    await programUpdateRef.set({ "sessions": newSessions }, { merge: true })
    setSessionFormLoading(false)
    message.success("更新成功")
  }

  const createSession = async () => {
    setCreateSessionLoading(true)
    let maxDay = 0
    if (program.data.sessions) {
      maxDay = Math.max(...program.data.sessions.map(p => p.triggerDay))
    }

    await programUpdateRef.set({
      "sessions": [...program.data.sessions, {
        'triggerDay': maxDay + 1,
        'triggerTime': '12:00:00',
        'description': ''
      }]
    }, { merge: true })
    setCreateSessionLoading(false)
  }


  if (program.status !== 'success' || formStatus !== 'success') {
    return <Spin />
  }
  return (
    <div>
      <Title level={2}>編輯模組</Title>
      <Row>
        <Col span={8}>
          <Card>
            <Title level={4}>設定</Title>
            <FormRender form={mainForm} schema={mainSchema} onMount={mainFormOnMount} onFinish={mainFormOnFinish} />
            <Button type="primary" onClick={mainForm.submit} loading={mainFormLoading}>更新</Button>
          </Card>
        </Col>
        <Col span={16}>
          <Card>
            <Title level={4}>Session Table</Title>
            <Table dataSource={program.data.sessions} key="index" columns={columns} />
            <Button type="primary" onClick={createSession} loading={createSessionLoading}>新增 Session</Button>
          </Card>
        </Col>
      </Row>

      <Divider />

      <Title level={4}>Session</Title>
      <FormRender form={sessionForm} schema={sessionSchema} watch={watch} onMount={sessionFormOnMount} onFinish={sessionFormOnFinish} />
      <Button type="primary" onClick={sessionForm.submit} loading={sessionFormLoading}>
        更新
      </Button>
    </div>
  )
}