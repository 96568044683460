import { FormsDetailAdminProps } from "../types/Route";
import { commonSettings, globalSettings, settings } from '../config/formGenerator'
import Generator from 'fr-generator';
import { useFirestore, useFirestoreDocData } from "reactfire";
import { Form } from "../types/Form";
import { Spin, message, Divider, Button } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import FormRender, { useForm, Error } from 'form-render';
import { OrganizationContext } from "../context/Organization";

const formSchema = {
    "type": "object",
    "properties": {
        "title": {
            "title": "標題",
            "type": "string",
            "required": true,
            "props": {}
        },
        "description": {
            "title": "備註",
            "type": "string",
            "required": true,
            "props": {}
        }
    },
    "displayType": "row"
}

export const FromsDetailAdmin = (props: FormsDetailAdminProps) => {
    const [schema, setSchema] = useState({})
    const organization = useContext(OrganizationContext)
    const formRef = useFirestore().collection('forms').doc(props.formId)
    const { data: form, status } = useFirestoreDocData<Form>(formRef)
    const genRef = useRef();
    const f = useForm()
    const [formLoading, setFormLoading] = useState(false)

    useEffect(() => {
        if (form) {
            setSchema(form.schema ? JSON.parse(form.schema) : {})
        }
    }, [form, setSchema])

    const onFinish = async (data: any, errors: Error[]) => {
        if (errors.length > 0) {
            message.warning("表單有錯誤")
            return
        }
        setFormLoading(true)
        // console.log(schema)
        await formRef.set({
            title: data.title,
            description: data.description,
            // schema: JSON.stringify(schema),
            organizationId: organization.NO_ID_FIELD
        }, { merge: true })
        setFormLoading(false)
        message.success("更新成功")
    }

    const onMount = () => {
        f.setValues({
            title: form.title || "",
            description: form.description || ""
        })
    }

    if (status !== "success") {
        return <Spin />
    }

    return <div>
        <FormRender form={f} schema={formSchema} onFinish={onFinish} onMount={onMount} />
        {/* <Button type="primary" onClick={f.submit} loading={formLoading} >
            儲存表格基本資訊
        </Button> */}
        <Divider></Divider>
        <Generator
            ref={genRef}
            defaultValue={schema ? schema : { "displayType": "column" }}
            globalSettings={globalSettings}
            extraButtons={
                [
                    true, true, true, true, true, {
                        text: '儲存',
                        onClick: async () => {
                            const s = (genRef.current as any).getValue();
                            const x = f.getValues();
                            await formRef.set({
                                schema: JSON.stringify(s),
                                title: x.title,
                                description: x.description,
                                organizationId: organization.NO_ID_FIELD
                            }, { merge: true })
                            message.success("儲存成功")
                        },
                        type: 'primary'
                    }
                ]
            }
            settings={settings}
            commonSettings={commonSettings} />
    </div>
}