import { navigate, Redirect, RouteComponentProps } from "@reach/router"
import { useUser, useFunctions, useAuth } from 'reactfire';
import { useLiff } from 'react-liff';
import { useEffect, useState } from 'react'
import 'firebase/functions';
import { useQuery } from '../hooks'
import { useSigninCheck } from 'reactfire';
import { Spin, message, Row } from "antd";
import { useSessionStorageState } from 'ahooks';
import firebase from 'firebase'
import Layout, { Content, Footer } from "antd/lib/layout/layout";
import { version } from '../../package.json';
import amplitude from 'amplitude-js';

const LiffLoginCallback = (props: RouteComponentProps) => {
    const { liff, ready, error, isLoggedIn } = useLiff();
    const auth = useAuth();
    const functions = useFunctions();
    const { data: userdata } = useUser();
    const formId = useQuery('formId') || "";
    const contentId = useQuery('contentId') || "";
    const liffState = useQuery('liff.state') || "";
    const { status, data: signInCheckResult } = useSigninCheck();
    const [nextURL, setnextURL] = useSessionStorageState('next-url', '');
    const [organizationStorage, setOrganizationStorage] = useSessionStorageState('organization', '');
    const [organizationIdStorage, setOrganizationIdStorage] = useSessionStorageState('organizationId', '');
    const organizationId = useQuery('organizationId') || organizationIdStorage;
    const organization = useQuery('organization') || organizationStorage;
    const [loginStatus, setLoginStatus] = useState("初始化")
    amplitude.getInstance().logEvent('LIFF_CALLBACK_START', {signInCheckResult, formId, contentId, liffState, organization, liffReady: ready});

    useEffect(() => {
        (async () => {
            if (!ready) {
                setLoginStatus("")
                return
            }
            setLoginStatus("")
            const accessToken = liff.getAccessToken();
            if (accessToken) {
                amplitude.getInstance().logEvent('LIFF_CALLBACK_ACCESS_TOKEN_GET', {accessToken})
                const login = functions.httpsCallable('lineLogin');
                const result = await login({ accessToken })
                amplitude.getInstance().logEvent('LIFF_CALLBACK_ACCESS_TOKEN_LOGIN_DONE')
                setLoginStatus("Line 登入完成")
                if (result.data.error) {
                    amplitude.getInstance().logEvent('LIFF_CALLBACK_ACCESS_TOKEN_LOGIN_ERROR', {error: result.data.error})
                    console.log(result.data.error)
                    setLoginStatus(`Line 登入失敗 ${JSON.stringify(result)}`)
                } else {
                    amplitude.getInstance().logEvent('LIFF_CALLBACK_ACCESS_TOKEN_LOGIN_SUCCESS')
                    setLoginStatus(`Line 登入成功 ${JSON.stringify(result)}`)
                    const s = await auth.signInWithCustomToken(result.data.token)
                    setLoginStatus(`爾科登入成功 ${JSON.stringify(s)}`)
                    const createAt = new Date();
                    await firebase.firestore().collection("lineUsers").doc(`${result.data.lineData.uid}+${organizationId}`).set(
                        { ...result.data.lineData, organizationId, createAt },
                        { merge: true }
                    )
                    setLoginStatus(`獲取使用者資料`)
                    console.log("create lineUser done")
                }
            }
        })()
    }, [liff, ready, auth, functions, userdata, organizationId]);

    useEffect(() => {
        if (liffState) {
            let params = new URLSearchParams(decodeURIComponent(liffState))
            const liffFormId = params.get("formId") || ''
            const liffContentId = params.get("contentId") || ''
            const liffOrganization = params.get("organization") || ''
            if (liffFormId) {
                setnextURL(`https://app.errkhealth.com/${liffOrganization}/liff/form/${liffFormId}`)
            }
            if (liffContentId) {
                setnextURL(`https://app.errkhealth.com/${liffOrganization}/liff/content/${liffContentId}`)
            }
        }
    }, [nextURL, setnextURL, liffState])

    if (status === 'loading') {
        amplitude.getInstance().logEvent('LIFF_CALLBACK_FIREBASE_LOADING')
        return (
            <Layout className="layout">
                <Content style={{ minHeight: '85vh' }}>
                    <Row justify="center" style={{ minHeight: '85vh' }}>
                        <Spin tip={`登入中`} />
                    </Row>
                </Content>
                <Footer style={{ textAlign: 'center' }}>v{version} | 爾科有限公司 ErrkHealth.com ©2021</Footer>
            </Layout>
        )

    }
    if (signInCheckResult.signedIn === true) {
        amplitude.getInstance().logEvent('LIFF_CALLBACK_START_REDIRECT')
        if (formId) {
            return <Redirect from="" to={`/${organization}/liff/form/${formId}`} noThrow />
        }
        if (contentId) {
            return <Redirect from="" to={`/${organization}/liff/content/${contentId}`} noThrow />
        }
        if (nextURL) {
            navigate(nextURL)
        }
        if (liffState) {
            let params = new URLSearchParams(decodeURIComponent(liffState))
            const liffFormId = params.get("formId") || ''
            const liffContentId = params.get("contentId") || ''
            const liffOrganization = params.get("organization") || ''
            if (liffFormId) {
                return <Redirect from="" to={`/${liffOrganization}/liff/form/${liffFormId}`} noThrow />
            }
            if (liffContentId) {
                return <Redirect from="" to={`/${liffOrganization}/liff/content/${liffContentId}`} noThrow />
            }
        }
        const url = window.location.href;
        return (
            <div>{JSON.stringify({ organization, formId, contentId, nextURL, url })}</div>
        )
    }
    else {
        setTimeout(()=>liff.login(), 5000)
        const firebaseSignIn = signInCheckResult.signedIn
        const d = {
            ready, error, isLoggedIn, loginStatus, firebaseSignIn
        }
        amplitude.getInstance().logEvent('LIFF_CALLBACK_NOT_LOGIN', d)
        return (
            <Row justify="center" style={{ minHeight: '85vh' }}>
                <Spin tip={`登入中 ${JSON.stringify(d)}`} />
            </Row>
        )
    }

}

export default LiffLoginCallback;